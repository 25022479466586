import { ActionIcon, Box, Button, Divider, Grid, Group, LoadingOverlay, Paper, Text, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import InputField, { InputFieldTypes } from '../components/input_field';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from '../services/companies';
import moment from 'moment';
// import { Channels, Flows } from '../components';

export default function MobileAppPage(){
    const {role, planData} = useAuth();
    const {startPlan} = useApp();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const [selected, setSelected] = React.useState(isMobile ? null : "general");
    const [loadingData, setLoadingData] = React.useState<boolean>(false);
    const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>({});

    const { hash } = useLocation();
    const navigate = useNavigate();

    const handleChange = dt => setData(u => ({ ...u, ...dt }))

    const loadData = () => {
        setLoadingData(true)
        GET_COMPANY_INTEGRATION("mobile-app")
        .then((res) => {
            setLoadingData(false)
            setData(res)
        })
        .catch(err => {
            setLoadingData(false)
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        SET_COMPANY_INTEGRATION("mobile-app", {...data})
        .then((res) => {
            setLoadingSave(false)
            notifications.show({message: "Mobile app settings updated", color: 'green'})
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => { loadData() }, [])

    const options = [
        {key: "general", title: "General" },
        {key: "playstore", title: "Play Store" },
        {key: "applestore", title: "Apple Store" },
        {key: "developer", title: "Developer" },
    ].filter(nn => nn)

    React.useEffect(() => {
        if (hash) {
            setSelected(hash.split("#")[1]);
        }else{
            setSelected(isMobile ? null : "general");
        };
      }, [hash, isMobile]);
    
    React.useEffect(() => {
        if (selected && !options.find(opt => opt.key === selected)) {
            setSelected("general");
        }
    }, [selected, isMobile]);

    return <>
        <Paper shadow='xs' style={{padding: 0, background: '#FFFFFF',}}>
            <LoadingOverlay visible={loadingData} />
            <Grid>
                {(!isMobile || !selected) && <Grid.Col span={{ base: 12, md: 4 }} style={{minHeight: '70vh'}}>
                    <Box style={{height: '100%', background: 'white', borderRight: '1px solid #DFDFDF'}}>
                        {options.map(({key, title}) => (
                            <UnstyledButton style={{width: '100%'}} onClick={() => {
                                navigate(`/mobile-app#${key}`);
                            }}>
                                <Group
                                    style={{borderLeft: `4px solid ${selected === key ? "black" : "transparent"}`}}
                                    p="xl"
                                >
                                    <Box style={{flex: 1}}>
                                        <Text size="md" fw="bold">{title}</Text>
                                    </Box>
                                </Group>
                                <Divider />
                            </UnstyledButton>
                        ))}
                    </Box>
                </Grid.Col>}
                {(!isMobile || selected) && <Grid.Col span={{ base: 12, md: 8 }} style={{height: '100%'}}>
                    {isMobile && <Group p="md">
                        <ActionIcon size="xl" onClick={() => navigate("/mobile-app")}><FaArrowLeft /></ActionIcon>
                    </Group>}
                    <Box p="md" pr="xl">
                        {selected === "general" && <GeneralMobileApp data={data} setData={handleChange} />}
                        {selected === "playstore" && <PlayStoreMobileApp data={data} setData={handleChange} />}
                        {selected === "applestore" && <AppleStoreMobileApp data={data} setData={handleChange} />}
                        {selected === "developer" && <DeveloperMobileApp data={data} setData={handleChange} />}

                        <Group mt="md" justify='flex-end'><Button
                            size="md"
                            onClick={handleSave} loading={loadingSave}>Save</Button></Group>
                    </Box>
                </Grid.Col>}
            </Grid>
        </Paper>
    </>
    
}

const DeveloperMobileApp = ({ data, setData }) => {

    const { userData } = useAuth();
    
    const handleChange = (dt) => {
        setData({...data, developer: { ...data?.developer, ...dt }})
    }

    return <div>
        
        <InputField
            name="bought"
            title="Bought"
            mb="md"
            onChange={() => handleChange({ bought: data?.developer?.bought ? null : moment().format() })}
            fieldType={InputFieldTypes.CHECKBOX}
            value={!!data?.developer?.bought}
        />

        <InputField
            name="deployed"
            title="Deployed"
            onChange={() => handleChange({ deployed: data?.developer?.deployed ? null : moment().format() })}
            disabled={!userData?.user?.isAdmin?.developer}
            mb="md"
            fieldType={InputFieldTypes.CHECKBOX}
            value={!!data?.developer?.deployed}
        />

        <InputField
            name="apple_url"
            title="Apple Store URL"
            onChange={handleChange}
            disabled={!userData?.user?.isAdmin?.developer}
            mb="md"
            value={data?.developer?.apple_url}
        />
        
        <InputField
            name="play_store_url"
            title="Play Store URL"
            onChange={handleChange}
            disabled={!userData?.user?.isAdmin?.developer}
            mb="md"
            value={data?.developer?.play_store_url}
        />

        <InputField
            name="package_id"
            title="Package ID"
            onChange={handleChange}
            disabled={!userData?.user?.isAdmin?.developer}
            mb="md"
            value={data?.developer?.package_id}
        />
        
        <InputField
            name="sku"
            title="SKU"
            onChange={handleChange}
            disabled={!userData?.user?.isAdmin?.developer}
            mb="md"
            value={data?.developer?.sku}
        />
        
        <InputField
            name="apple_id"
            title="Apple ID"
            onChange={handleChange}
            disabled={!userData?.user?.isAdmin?.developer}
            mb="md"
            value={data?.developer?.apple_id}
        />

    </div>
}
const GeneralMobileApp = ({ data, setData }) => {
    
    const handleChange = (dt) => {
        setData({...data, general: { ...data?.general, ...dt }})
    }

    return <div>
        <InputField
            name="title"
            title="App Name"
            onChange={handleChange}
            mb="md"
            value={data?.general?.title}
        />
        <InputField
            name="keywords"
            title="Keywords"
            placeholder=''
            onChange={handleChange}
            mb="md"
            value={data?.general?.keywords}
        />
        <InputField
            name="copyright"
            title="Copyright"
            placeholder=''
            onChange={handleChange}
            mb="md"
            value={data?.general?.copyright}
        />
        <InputField
            name="subtitle"
            title="App Subtitle"
            description={`${(data?.general?.subtitle ?? "").length}/30`}
            onChange={handleChange}
            mb="md"
            value={data?.general?.subtitle}
        />
        <InputField
            name="short_description"
            title="Short Description"
            onChange={handleChange}
            mb="md"
            maxRows={2}
            description={`${(data?.general?.short_description ?? "").length}/80`}
            maxLength={80}
            fieldType={InputFieldTypes.TEXTAREA}
            value={data?.general?.short_description}
        />
        <InputField
            name="full_description"
            title="Full Description"
            onChange={handleChange}
            mb="md"
            maxRows={4}
            description={`${(data?.general?.full_description ?? "").length}/4000`}
            maxLength={4000}
            fieldType={InputFieldTypes.TEXTAREA}
            value={data?.general?.full_description}
        />

        <InputField
            name="splash"
            title="Splash Screen"
            fieldType={InputFieldTypes.IMAGE}
            onChange={handleChange}
            mb="md"
            value={data?.general?.splash}
        />
    </div>
}

const PlayStoreMobileApp = ({ data, setData }) => {
    
    const handleChange = (dt) => {
        setData({...data, playstore: { ...data?.playstore, ...dt }})
    }

    return <div>
        <InputField
            title={"Icon"}
            name='icon'
            mb="md"
            onChange={handleChange}
            description={<span>The app icon must be a PNG or JPEG of up to 1 MB and 512 x 512px, in addition to complying with the <a href="https://developer.android.com/google-play/resources/icon-design-specifications" target="_blank">design specifications</a> and <a href="https://support.google.com/googleplay/android-developer/answer/9898842" target='_blank'>metadata policy</a></span>}
            fieldType={InputFieldTypes.IMAGE}
            value={data?.playstore?.icon}
        />
        <InputField
            title={"Graphic Resource"}
            name='graphic'
            mb="md"
            onChange={handleChange}
            description={<span>The graphic resource must be a PNG or JPEG image up to 15 MB and 1024px x 500px</span>}
            fieldType={InputFieldTypes.IMAGE}
            value={data?.playstore?.graphic}
        />
        <InputField
            title={"Video Url"}
            name='video_url'
            mb="md"
            onChange={handleChange}
            placeholder="https://www.youtube.com/watch?v="
            description="Enter a YouTube URL to add a video. The video must be public or unlisted, be in landscape mode, have no age restrictions and have ads turned off."
            value={data?.playstore?.video_url}
        />
        <InputField
            title={"Phone Screenshots"}
            name='phone_screenshots'
            mb="md"
            onChange={handleChange}
            multiple
            description={<span>Upload 2 to 8 smartphone screenshots. Captures must be PNG or JPEG images of up to 8 MB each with a 16:9 or 9:16 aspect ratio and each side measuring between 320 and 3,840 px. <br/><br/>To qualify for the promotion, include at least four screenshots with at least 1,080 px on each side. <a href="https://support.google.com/googleplay/android-developer/answer/9866151#screenshots" target='_blank'>View content guideliness</a></span>}
            fieldType={InputFieldTypes.IMAGE}
            value={data?.playstore?.phone_screenshots}
        />
        <InputField
            title={"Table Screenshots of the 7-inch tablet *"}
            name='tablet_7_inch_screenshots'
            mb="md"
            onChange={handleChange}
            multiple
            description={<span>Upload up to 8 screenshots on 7-inch tablets. Captures must be PNG or JPEG images of up to 8 MB each with a 16:9 or 9:16 aspect ratio and each side measuring between 320 and 3,840 px</span>}
            fieldType={InputFieldTypes.IMAGE}
            value={data?.playstore?.tablet_7_inch_screenshots}
        />
        <InputField
            title={"Table Screenshots of the 10-inch tablet *"}
            name='tablet_10_inch_screenshots'
            mb="md"
            onChange={handleChange}
            multiple
            description={<span>Upload up to 8 screenshots on 10-inch tablets. Captures must be PNG or JPEG images of up to 8 MB each with a 16:9 or 9:16 aspect ratio and each side measuring between 1080 and 7680px</span>}
            fieldType={InputFieldTypes.IMAGE}
            value={data?.playstore?.tablet_10_inch_screenshots}
        />
    </div>
}

const AppleStoreMobileApp = ({ data, setData }) => {
    
    const handleChange = (dt) => {
        setData({...data, applestore: { ...data?.applestore, ...dt }})
    }

    return <div>
        <InputField
            title={"Icon"}
            name='icon'
            mb="md"
            onChange={handleChange}
            fieldType={InputFieldTypes.IMAGE}
            value={data?.applestore?.icon}
        />

        <Button variant='light' mb="md" color="orange" size="sm" onClick={() => window.open("https://developer.apple.com/help/app-store-connect/reference/screenshot-specifications/", "_blank")}>
            View all sizes specifications
        </Button>

        <InputField
            title={"Iphone Screenshots 6,7 inch"}
            name='iphone_6_7_inch_screenshots'
            mb="md"
            onChange={handleChange}
            multiple
            fieldType={InputFieldTypes.IMAGE}
            value={data?.applestore?.iphone_6_7_inch_screenshots}
        />
        <InputField
            title={"Iphone Screenshots 6,5 inch"}
            name='iphone_6_5_inch_screenshots'
            mb="md"
            onChange={handleChange}
            multiple
            fieldType={InputFieldTypes.IMAGE}
            value={data?.applestore?.iphone_6_5_inch_screenshots}
        />
        <InputField
            title={"Iphone Screenshots 5,5 inch"}
            name='iphone_5_5_inch_screenshots'
            mb="md"
            onChange={handleChange}
            multiple
            fieldType={InputFieldTypes.IMAGE}
            value={data?.applestore?.iphone_5_5_inch_screenshots}
        />
        <InputField
            title={"Ipad Screenshots 12,9 inch (6th generation)"}
            name='ipad_12_9_inch_6_gen_screenshots'
            mb="md"
            onChange={handleChange}
            multiple
            fieldType={InputFieldTypes.IMAGE}
            value={data?.applestore?.ipad_12_9_inch_6_gen_screenshots}
        />
        <InputField
            title={"Ipad Screenshots 12,9 inch (2th generation)"}
            name='ipad_12_9_inch_6_gen_screenshots'
            mb="md"
            onChange={handleChange}
            multiple
            fieldType={InputFieldTypes.IMAGE}
            value={data?.applestore?.ipad_12_9_inch_6_gen_screenshots}
        />
    </div>
}