import axios from "axios";
import Cookies from "js-cookie";
import MockupAxios from "../utility/mockups/MockupAxios";

const MOCKUP = false;
const baseURL = process.env.REACT_APP_SERVER_URL;
let api = MOCKUP ? new MockupAxios() : axios.create({ baseURL });

api.interceptors.request.use((config) => {
  const localUser = localStorage.getItem("@retainr/user");
  const localRole = localStorage.getItem("@retainr/role");
  const user = localUser ? JSON.parse(localUser) : null;
  const role = localRole ? JSON.parse(localRole) : null;
  const language = localStorage.getItem("@retainr/language") ?? "en-US"

  const fbp = Cookies.get("_fbp");
  const fbc = Cookies.get("_fbc");
  const ttp = Cookies.get("_ttp");
  const ttclid = Cookies.get("_ttclid");

  if (user && user.token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${user.token}`,
      Role: role?._id,
    };
  }
  config.headers = {
    language,
    ...config.headers,
    ...(fbp ? { fbp } : {}),
    ...(fbc ? { fbc } : {}),
    ...(ttp ? { ttp } : {}),
    ...(ttclid ? { ttclid } : {}),
  }

  return config;
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const {response} = error;
  let message = Array.isArray(response.data?.message) ? response.data?.message.join(", ") : response.data?.message;
  if(message === 'Network Error') message = "Consulta indisponível no momento";
  if(message){ throw new Error(message); }
  throw error;
});

export {api};