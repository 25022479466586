import { ActionIcon, Box, Button, Divider, Grid, Group, Image, Loader, Paper, Popover, Table, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaInfoCircle, FaLongArrowAltRight, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import InputField, { InputFieldMasks, InputFieldTypes } from '../components/input_field';
import { RenderService } from '../components/service';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { SAVE_JOB } from '../services/jobs';
import { GET_ALL_SERVICES } from '../services/services';
import { GET_ALL_USERS } from '../services/users';
import { extenseRecurrenceType } from '../utility/util';

export default function NewJobPage({ service = null, title = null, onClose = null, justify = "left" }){
    const [loadingServices, setLoadingServices] = useState(false);
    const [loadingCustomers, setLoadingCustomers] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [services, setServices] = useState<any[]>([]);
    const [customers, setCustomers] = useState<any[]>([]);
    const [formData, setFormData] = useState<any>({});

    const selectedService = services.find(s => s._id === formData?.service);

    let vl = 0;
    if(selectedService?.pricing_table){
        vl += (formData.table ?? []).reduce((pv, t) => {
            const tItem = (selectedService?.table ?? []).find((item, i) => i === t.index);
            return (
                pv + ((t.quantity ?? 0) * (
                    tItem.pricing_structure === "custom" 
                        ? (t.unity_price ?? 0)
                        : tItem?.unity_price ?? 0
                ))
            )
        }, 0)
    }else{
        if(selectedService?.charge_setup && selectedService?.plan_type === "subscription"){
            // vl += selectedService?.pricing_structure === "custom"
            // ? parseFloat(formData.setup_value || "0")
            // : parseFloat(selectedService?.setup_value || "0");
        }
        if(selectedService?.pricing_structure === "custom"){
            vl += parseFloat(formData?.unity_price || "0");
        }else if(selectedService?.pricing_structure === "quantity"){
            vl += parseFloat(selectedService?.unity_price || "0") * formData.quantity;
        }else{
            vl += parseFloat(selectedService?.unity_price || "0");
        }
    }

    const navigate = useNavigate();
    const { setNewOrder } = useApp();
    const { role, user: currentUser } = useAuth();

    const getItemValue = (t, i) => {
        if(!t) return 0;
        return (
            (
                (
                    t.pricing_structure === "fixed"
                    ? 1
                    : ((formData?.table ?? []).find(item => item.index === i)?.quantity)
                ) * 
                parseFloat(
                    t.pricing_structure === "custom"
                    ? (formData?.table ?? []).find(item => item.index === i)?.unity_price
                    : t.unity_price
                ) || 0
            )
        ).toFixed(2)
    }

    const handleSave = () => {
        setLoadingSave(true);

        let params = {...formData};
        if(role?.profile?.title === "Client"){ params.customer = currentUser._id }

        SAVE_JOB(params)
        .then(res => {
            setLoadingSave(false);
            setNewOrder(null);
            navigate(`/orders/${res._id}`)
        })
        .catch(err => { 
            setLoadingSave(false)
            notifications.show({message: err.message, color: "red"})
        })
    }
    
    const loadServices = () => {
        setLoadingServices(true);
        GET_ALL_SERVICES()
        .then(res => {
            setServices(res.filter(r => r.status === "active"));
            setLoadingServices(false);
        })
        .catch(err => { 
            setLoadingServices(false)
            notifications.show({message: err.message, color: "red"})
        })
    }
    
    const loadCustomers = () => {
        if(role?.profile?.title !== "Client"){
            setLoadingCustomers(true);
            GET_ALL_USERS()
            .then(res => {
                setCustomers(res.filter(r => r.profile?.title === "Client"));
                setLoadingCustomers(false);
            })
            .catch(err => { 
                setLoadingCustomers(false)
                notifications.show({message: err.message, color: "red"})
            })
        }
    }

    useEffect(() => {
        loadServices()
        loadCustomers()
    }, []);
    
    useEffect(() => {
        if(service) setFormData(fd => ({ ...fd, service }))
    }, [services, service]);
    
    useEffect(() => {
        document.getElementById("principal-content").scrollTo({top: 0, behavior: "smooth"});

        if(selectedService){
            setFormData(fd => ({...fd, name: selectedService.plan_name, table: [], quantity: 1}))
        }
    }, [selectedService]);
    
    useEffect(() => {
        let nt = [];
        (selectedService?.table ?? []).forEach((t, i) => {
            if(t.min_quantity) nt.push({ index: i, quantity: t.min_quantity })
        })
        setFormData(fd => ({ ...fd, table: nt }))
    }, [selectedService]);
    
    return <div>

        {!selectedService && <>
            <Group>
                <div style={{flex: 1}}>
                    {title ?? <>
                        <Title order={3}>{role?.profile?.title !== "Client" ? "Create Offer" : "Place an Order"}</Title>
                        <Title order={5} c="gray">Service Packages</Title>
                    </>}
                </div>
                {onClose && <ActionIcon onClick={onClose}>
                    <FaTimes />
                </ActionIcon>}
            </Group>
        
            {loadingServices && <Loader mt="xl" />}
            <Grid mt="md" mb="md" style={{justifyContent: justify}}>
                {services.map(service => (
                    <Grid.Col span={{ base: 12, md: services.length <= 1 ? 4 : services.length <= 2 ? 6 : services.length > 3 ? 3 : 4}}>
                        <RenderService service={service} onClick={() => {
                            setFormData(fd => ({...fd, service: service._id}))
                        }} />
                    </Grid.Col>
                ))}
            </Grid>
        </>}

        {services.length === 0 && role?.profile?.title !== "Client" && <Group justify='center' style={{ flexDirection: 'column' }} mt="xl" mb="xl">
            <Title order={5} c="gray" mb="sm">You need at least one package to create orders.</Title>
            <Button onClick={() => { onClose && onClose(); navigate("/services")}} className='draw-button'>Create Service Package</Button>
        </Group>}

        {selectedService && <>
            <Grid>
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Group>
                        <ActionIcon size="xl" variant="outline" color="gray" onClick={() => setFormData(fd => ({...fd, service: null}))}><FaArrowLeft /></ActionIcon>
                        <Title order={3}>{role?.profile?.title !== "Client" ? "Create Offer" : "Place an Order"}</Title>
                    </Group>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Paper style={{borderColor: "#DFDFDF"}} p="xl">
                        <Group align="flex-start">
                            <Box style={{flex: 1}}>
                                <Title order={3}>{selectedService.plan_name}</Title>
                                <Text c="black" size="sm">{(selectedService.description ?? "").split("\n").map((d) => <div>{d}</div>)}</Text>
                            </Box>
                            {selectedService.pricing_structure === "quantity" && <Box>
                                <Text mb="md" size="lg">Quantity</Text>
                                <InputField
                                    name="quantity"
                                    readOnly={false}
                                    fieldType={InputFieldTypes.QUANTITY}
                                    value={formData.quantity}
                                    min={1}
                                    onChange={({quantity}) => {
                                        setFormData(fd => ({...fd, quantity}));
                                    }}
                                    size="xs"
                                />
                            </Box>}
                        </Group>
                    </Paper>
                </Grid.Col>
                {selectedService.pricing_table && <Grid.Col span={{ base: 12, md: 12 }}>
                    <Title order={3} mt="md">Customize your order to continue</Title>
                    <Paper p="md" style={{borderColor: '#DFDFDF'}} mt="xs">
                        <Table>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>Quantity</Table.Th>
                                    <Table.Th>Item</Table.Th>
                                    {role?.profile?.title !== "Client" && <Table.Th>Custom</Table.Th>}
                                    <Table.Th>Total</Table.Th>
                                    <Table.Th></Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {(selectedService.table ?? []).map((t, i) => 
                                    <Table.Tr>
                                        <Table.Td>
                                            <InputField
                                                name="quantity"
                                                readOnly={false}
                                                fieldType={InputFieldTypes.QUANTITY}
                                                value={(formData?.table ?? []).find(item => item.index === i)?.quantity ?? 0}
                                                min={t.min_quantity}
                                                max={t.max_quantity}
                                                centerSection={<Text ta="center" size="xs" c="black">{t.unit}</Text>}
                                                onChange={({quantity}) => {
                                                    setFormData(fd => ({
                                                        ...fd,
                                                        table: [
                                                            ...(fd.table ?? []).filter(item => item.index !== i),
                                                            { ...(fd?.table ?? []).find(item => item.index === i), index: i, quantity }
                                                        ]
                                                    }))
                                                }}
                                            />
                                        </Table.Td>
                                        <Table.Td>
                                            <Text style={{flex: 1}}>{t.title}</Text>
                                        </Table.Td>
                                        {role.profile.title !== "Client" && <Table.Td>
                                        {((formData?.table ?? []).find(item => item.index === i)?.quantity ?? 0) > 0 && 
                                            <Group>
                                                {t.pricing_structure === "custom" && selectedService.plan_type === "one-time" && <InputField
                                                    placeholder={`Delivery Period (in days)`}
                                                    name="delivery_period"
                                                    mb="xs"
                                                    fieldType={InputFieldTypes.NUMBER}
                                                    value={(formData?.table ?? []).find(item => item.index === i)?.delivery_period}
                                                    onChange={({delivery_period}) => {
                                                        setFormData(fd => ({
                                                            ...fd,
                                                            table: [
                                                                ...(fd.table ?? []).filter(item => item.index !== i),
                                                                { ...(fd?.table ?? []).find(item => item.index === i), index: i, delivery_period }
                                                            ]
                                                        }))
                                                    }}
                                                />}
                                                {t.pricing_structure === "custom" && <InputField
                                                    placeholder={`Unity Price`}
                                                    name="unity_price"
                                                    mb="xs"
                                                    mask={InputFieldMasks.MONEY}
                                                    value={(formData?.table ?? []).find(item => item.index === i)?.unity_price}
                                                    onChange={({unity_price}) => {
                                                        setFormData(fd => ({
                                                            ...fd,
                                                            table: [
                                                                ...(fd.table ?? []).filter(item => item.index !== i),
                                                                { ...(fd?.table ?? []).find(item => item.index === i), index: i, unity_price }
                                                            ]
                                                        }))
                                                    }}
                                                />}
                                            </Group>}
                                        </Table.Td>}
                                        <Table.Td>
                                            {
                                                ((formData?.table ?? []).find(item => item.index === i)?.quantity ?? 0) > 0 ? (
                                                    (t.pricing_structure === "custom" && role?.profile?.title === "Client")
                                                    ? <Text>Agency will calculate the<br/>pricing after your request</Text>
                                                    : <Text>{selectedService.currency} {getItemValue(t, i)}</Text>
                                                ) : "-"
                                            }
                                        </Table.Td>
                                        <Table.Td>
                                        {t.description && <Popover width={500} position="bottom" withArrow shadow="md">
                                            <Popover.Target>
                                                <ActionIcon color="yellow" variant='light'><FaInfoCircle /></ActionIcon>
                                            </Popover.Target>
                                            <Popover.Dropdown>
                                                <Text size="xs">{(t.description ?? "")}</Text>
                                            </Popover.Dropdown>
                                        </Popover>}
                                        </Table.Td>
                                    </Table.Tr>
                                )}
                            </Table.Tbody>
                        </Table>
                    </Paper>
                </Grid.Col>}
                <Grid.Col span={{ base: 12, md: 7 }}>
                    <Paper style={{borderColor: '#DFDFDF'}} p="md">
                        {/* {role?.profile?.title !== "Client" && <InputField
                            placeholder="Select Client"
                            name="customer"
                            clearable={false}
                            value={formData.customer}
                            fieldType={InputFieldTypes.SELECT}
                            onChange={({customer}) => setFormData(fd => ({...fd, customer}))}
                            options={customers.map(c => ({label: c.name, value: c._id}))}
                        />} */}
                        <InputField
                            title={
                                role?.profile?.title === "Client"
                                ? "Order Title"
                                : "Clear phrase of what you're going to offer"
                            }
                            name="name"
                            value={formData.name}
                            onChange={({name}) => setFormData(fd => ({...fd, name}))}
                        />
                        <InputField
                            mt="lg"
                            title={
                                role?.profile?.title === "Client"
                                ? "Order Description / Notes"
                                : "What's your service about?"
                            }
                            name="description"
                            value={formData.description}
                            fieldType={InputFieldTypes.TEXTAREA}
                            onChange={({description}) => setFormData(fd => ({...fd, description}))}
                        />
                        {
                            !selectedService.pricing_table && selectedService.pricing_structure === "custom" && role?.profile?.title !== "Client" && <>
                                {selectedService.charge_setup && <InputField
                                    mt="lg"
                                    title="Setup Fee"
                                    name="setup_value"
                                    placeholder='Add any additional tax or setup fee you seem necessary'
                                    mask={InputFieldMasks.MONEY}
                                    value={formData.setup_value}
                                    onChange={({setup_value}) => setFormData(fd => ({...fd, setup_value}))}
                                />}
                                <InputField
                                    mt="lg"
                                    title={`Price (${selectedService.plan_type === "one-time" ? "One Time" : extenseRecurrenceType(selectedService.recurrence_type)})`}
                                    name="unity_price"
                                    placeholder={
                                        selectedService.plan_type === "one-time"
                                        ? `The amount your client will be billed oce`
                                        : `The monthly amount your client will be billed`
                                    }
                                    mask={InputFieldMasks.MONEY}
                                    value={formData.unity_price}
                                    onChange={({unity_price}) => setFormData(fd => ({...fd, unity_price}))}
                                />
                                {selectedService.plan_type === "one-time" && <InputField
                                    mt="lg"
                                    title={`Delivery Period (in days)`}
                                    name="delivery_period"
                                    placeholder='How long in days the job will be delivered'
                                    fieldType={InputFieldTypes.NUMBER}
                                    value={formData.delivery_period}
                                    onChange={({delivery_period}) => setFormData(fd => ({...fd, delivery_period}))}
                                />}
                            </>
                        }
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 5 }}>
                    <Paper style={{borderColor: "#DFDFDF"}} p="xl">
                        <Title order={4}>Total</Title>

                        <Paper mt="md" p="md" style={{background: '#EFEFEF', borderColor: "#DFDFDF"}}>
                            {selectedService?.charge_setup && selectedService?.plan_type === "subscription" && <>
                                <Text size="xs">Setup Fee</Text>
                                <Text size="xs" mt="xs" c="gray">{selectedService.currency} {(
                                    parseFloat(selectedService?.pricing_structure === "custom"
                                    ? formData.setup_value || "0"
                                    : selectedService?.setup_value || "0").toFixed(2)
                                )}</Text>
                                <Divider c="#DFDFDF" mt="md" mb="md" />
                            </>
                            }
                            {selectedService?.pricing_structure === "quantity" && <>
                                <Group align="flex-start">
                                    <Box style={{flex: 1}}>
                                        <Text size="xs">Setup Fee</Text>
                                        <Text size="xs" mt="xs" c="gray">{selectedService.currency} {(
                                            parseFloat(selectedService?.unity_price || "0").toFixed(2)
                                        )} x {formData.quantity} items</Text>
                                    </Box>
                                    <Text size="xs">{selectedService.currency} {(parseFloat(selectedService?.unity_price || "0") * formData.quantity).toFixed(2)}</Text>
                                </Group>
                                <Divider c="#DFDFDF" mt="md" mb="md" />
                            </>
                            }
                            {
                                (formData.table ?? []).filter(t => t.quantity > 0).map((t, i) => <>
                                    <Group>
                                        <Text style={{flex: 1}} size="xs">{(selectedService.table ?? []).find((it, j) => j === t.index)?.title}</Text>
                                        <Text size="xs" c="gray">{selectedService.currency} {getItemValue((selectedService.table ?? []).find((it, j) => j === t.index), t.index)}</Text>
                                    </Group>
                                    <Divider c="#DFDFDF" mt="xs" mb="xs" />
                                </>)
                            }
                            <Text size="xs">Total Amount</Text>
                            <Text size="xs" mt="xs" fw="bold">{selectedService.currency} {((formData.seats ?? 1) * vl).toFixed(2)} {selectedService.plan_type === "one-time" ? "One Time" : ` each ${selectedService.recurrence_period ?? 1} ${(selectedService.recurrence_type ?? "month(s)")}`}</Text>
                        </Paper>
                    </Paper>
                    
                </Grid.Col>
                
                <Grid.Col span={{ base: 12, md: 12 }}>
                    <Button loading={loadingSave} onClick={handleSave} mt="lg" variant="filled" style={{borderWidth: 1, borderColor: 'black'}} fullWidth size="lg" color="orange" rightSection={<FaLongArrowAltRight />}>Submit Order</Button>
                </Grid.Col>
                
            </Grid>
        </>}
    </div>
}
