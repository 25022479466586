import { Accordion, Box, Button, Grid, Group, Loader, Paper, PasswordInput, Text, TextInput, Title, UnstyledButton } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import InputField, { InputFieldTypes } from "../components/input_field";
import { GET_COMPANY_DOMAINS, SAVE_COMPANY_DOMAIN, UPDATE_SELF_COMPANY } from "../services/companies";
import { GET_USER_DATA } from "../services/users";
import FieldsPage from "./fields";
import Login from "./login";
import { useApp } from "../contexts/app.context";

export default function Whitelabel(){
    const [settings, setSettings] = useState<any>(null);
    const [loading, setLoading] = useState<any>(null);
    const [domains, setDomains] = useState<any[]>([]);

    const defaultDomain = domains.find(d => d.is_default);
    const notDefaultDomain = domains.find(d => !d.is_default);

    const { configs, setConfigs } = useApp();
    const clipboard = useClipboard();

    const loadData = () => {
        setLoading(true);
        GET_USER_DATA()
        .then(({company}) => {
            setSettings({...company?.appearance});
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const loadDomains = () => {
        GET_COMPANY_DOMAINS()
        .then((res) => {
            setDomains(res);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }

    const handleSaveDomain = (domain) => {
        SAVE_COMPANY_DOMAIN(domain)
        .then((res) => {
            loadDomains();
            notifications.show({ title: "Domain published", message: "Create records in your DNS manager", color: "green" })
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }

    const handleSaveWhitelabel = () => {
        UPDATE_SELF_COMPANY({appearance: settings})
        .then(() => {
            notifications.show({ message: "Updated!", color: "green" })
        })
        .catch(err => {
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    useEffect(() => {
        loadData()
        loadDomains()
    }, [])

    return <div style={{position: 'relative'}}>
        {loading && <Loader />}
        <Title order={4}>Your custom link</Title>
        <Title mb="md" order={5} c="gray">Your Clients will Signup using this link</Title>
        <Paper style={{background: '#DFDFDF'}} p="md" mb="md">
            <Group>
                <Title style={{flex: 1}} order={5} c="gray">{defaultDomain?.url}</Title>
                <Button onClick={() => {
                    clipboard.copy(defaultDomain?.url);
                    notifications.show({ message: "Copied to clipboard", color: "yellow" })
                }} color="white" style={{color: 'gray'}}>Copy</Button>
            </Group>
        </Paper>
        <Accordion>
            <Accordion.Item mb="md" value="appearance" style={{background: 'white'}}>
                <Accordion.Control><Title order={4}>Appearance</Title></Accordion.Control>
                <Accordion.Panel>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Paper p="md" mb="md" shadow="xs">
                                <Title order={4}>Change Color Scheme</Title>
                                <Text c="gray" size="md">Choose your primary color that will be used in principal data.</Text>
                                <InputField
                                    mt="md"
                                    name="primary"
                                    fieldType={InputFieldTypes.COLOR}
                                    value={configs.primary}
                                    onChange={({primary}) => {
                                        setConfigs({ primary });
                                        setSettings(s => ({...s, colors: {
                                            ...s?.colors,
                                            primary
                                        }}));
                                    }}
                                />
                            </Paper>
                        </Grid.Col>
                        <Grid.Col span={{ base: 12, md: 6 }}>
                            <Paper p="md" mb="md" shadow="xs">
                                <Title order={4}>Navbar Color</Title>
                                <Text c="gray" size="md">Choose your primary color that will be used in principal data.</Text>
                                <Group mt="md">
                                    <Box style={{
                                        height: 30,
                                        width: 30,
                                        borderRadius: 2,
                                        border: '1px solid #EFEFEF',
                                        background: configs.brand
                                    }}></Box>
                                    <InputField
                                        name="navbar"
                                        style={{flex: 1}}
                                        fieldType={InputFieldTypes.SELECT}
                                        clearable={false}
                                        searchable={false}
                                        options={[
                                            { label: "White", value: "white" },
                                            { label: "Black", value: "black" },
                                            { label: "Orange", value: "orange" },
                                            { label: "Yellow", value: "yellow" },
                                            { label: "Green", value: "green" },
                                            { label: "Blue", value: "blue" },
                                            { label: "Pink", value: "pink" },
                                        ]}
                                        value={configs.navbar || "white"}
                                        onChange={({navbar}) => {
                                            setConfigs({ navbar });
                                            setSettings(s => ({...s, colors: {
                                                ...s?.colors,
                                                navbar
                                            }}));
                                        }}
                                    />
                                </Group>
                            </Paper>
                        </Grid.Col>
                    </Grid>
                    <Paper style={{padding: 0, margin: '0 0 20px 0'}}>
                        <LoginPreview buttonColor={configs.primary} color={configs.brand} contrast={configs.contrast} />
                    </Paper>
                    <Group justify="flex-end">
                        <Button size="md" onClick={handleSaveWhitelabel} style={{background: 'black'}}>Save Appearance</Button>
                    </Group>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item mb="md" value="texts" style={{background: 'white'}}>
                <Accordion.Control><Title order={4}>Headlines & Descriptions</Title></Accordion.Control>
                <Accordion.Panel>
                    <Grid>
                        <Grid.Col span={{ base: 12, md: 12 }}>
                            <Paper p="md" mb="md" shadow="xs">
                                <Title order={4}>Login/Sign-up Page Copy</Title>
                                <Text c="gray" size="md">Edit your texts on login page</Text>
                                <InputField
                                    mt="md"
                                    name="login_footer_one"
                                    title="Client Name"
                                    value={settings?.texts?.login_footer_one ?? "Ready to Experience Excellence?"}
                                    onChange={({login_footer_one}) => setSettings(s => ({...s, texts: {
                                        ...s?.texts,
                                        login_footer_one
                                    }}))}
                                />
                                <InputField
                                    mt="md"
                                    name="login_footer_two"
                                    title="Client Testimonial"
                                    fieldType={InputFieldTypes.TEXTAREA}
                                    value={settings?.texts?.login_footer_two ?? "Explore our array of top-tier services tailored to meet your needs. Sign up today and unlock the gateway to unparalleled solutions delivered by our dedicated team."}
                                    onChange={({login_footer_two}) => setSettings(s => ({...s, texts: {
                                        ...s?.texts,
                                        login_footer_two
                                    }}))}
                                />
                            </Paper>
                        </Grid.Col>
                    </Grid>
                    <Group justify="flex-end">
                        <Button size="md" onClick={handleSaveWhitelabel} style={{background: 'black'}}>Save Description</Button>
                    </Group>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item mb="md" value="fields" style={{background: 'white'}}>
                <Accordion.Control><Title order={4}>Edit Client Signup Form</Title></Accordion.Control>
                <Accordion.Panel>
                    <FieldsPage />
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item mb="md" value="domains" style={{background: 'white'}}>
                <Accordion.Control><Title order={4}>Connect your domain</Title></Accordion.Control>
                <Accordion.Panel>
                    <Title mb="xs" order={4}>Connect your Domain</Title>
                    {/* <Text c="gray" mb="md">Paste your Domain.</Text> */}
                    <Paper style={{background: '#DFDFDF'}} p="md" mb="md">
                        <Group>
                            <InputField
                                name="url"
                                variant="filled"
                                color="gray"
                                placeholder="orders.example.com"
                                style={{flex: 1}}
                                value={notDefaultDomain?.url}
                                onChange={({url}) => {
                                    setDomains(d => notDefaultDomain
                                        ? d.map(item => (item._id === notDefaultDomain?._id ? {...item, url} : item))
                                        : [...d, { url }]
                                    )
                                }}
                            />
                            <Group justify="flex-end">
                                <Button size="md" onClick={() => handleSaveDomain(notDefaultDomain)} style={{background: 'black'}}>Publish</Button>
                            </Group>
                        </Group>
                    </Paper>
                    {notDefaultDomain && <>
                        DNS Records
                        <Paper p="md" mt="md" style={{background: "#DFDFDF"}}>
                            <Grid>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 2 }}>Type</Grid.Col>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 5 }}>Record</Grid.Col>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 5 }}>Target</Grid.Col>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 2 }}><Paper shadow="xs" p="sm">CNAME</Paper></Grid.Col>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 5 }}><Paper shadow="xs" p="sm">{notDefaultDomain?.url}</Paper></Grid.Col>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 5 }}><Paper shadow="xs" p="sm">app.retainr.io</Paper></Grid.Col>
                                <Grid.Col span={{ base: 12, xs: 2 }} style={{padding: 0}}>
                                    <Title c="black" order={5} ta="center">OR</Title>
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, xs: 12 }} style={{margin: 0, padding: 0}}></Grid.Col>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 2 }}><Paper shadow="xs" p="sm">A</Paper></Grid.Col>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 5 }}><Paper shadow="xs" p="sm">{notDefaultDomain?.url}</Paper></Grid.Col>
                                <Grid.Col style={{textAlign: 'center'}} span={{ base: 12, xs: 5 }}><Paper shadow="xs" p="sm">34.200.20.188</Paper></Grid.Col>
                            </Grid>
                        </Paper>
                    </>}
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    </div>
}


const LoginPreview = ({ buttonColor, color, contrast }) => <div style={{height: '340px', display: 'flex', flexDirection: 'row', overflow: 'auto'}}>
    <div style={{ flex: 0.3, display: 'flex', flexDirection: 'column', background: color }}>
        <div style={{flex: 1, padding: '20px 20px'}}>
            <Box>
                <Text color={contrast} size="md">Welcome Back</Text>
            </Box>
            <TextInput 
                mt="xs"
                label="E-mail"
                name="email"
                size="xs"
                c={contrast}
            />
            <PasswordInput
                mt="xs"
                label="Password"
                name="password"
                size="xs"
                c={contrast}
            />
            <UnstyledButton style={{width: '100%'}}>
                <Text style={{textDecoration: 'undeline'}} size="xs" c="gray" ta="right">Forgot password?</Text>
            </UnstyledButton>
            <Button style={{background: buttonColor}} className='draw-button' type="submit" mt="xl" size="xs" fullWidth>
                Sign In
            </Button>
        </div>
    </div>
    <div style={{flex: 0.7, background: `url(/assets/login.png)`, height: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
</div>