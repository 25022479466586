import { ActionIcon, Avatar, Badge, Box, Button, Divider, Grid, Group, LoadingOverlay, Menu, Modal, Paper, Progress, Text, Title, Tooltip, UnstyledButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect, useRef, useState } from 'react';
import { FaAlignJustify, FaArchive, FaCalendarDay, FaCheck, FaClipboard, FaClock, FaComment, FaComments, FaEdit, FaEllipsisV, FaEye, FaGripLines, FaPaperclip, FaPlus, FaRegClock, FaRegComment, FaTimes, FaTrash, FaUser } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerAvatar from '../components/customer_avatar';
import SimpleHeader from '../components/simple_header';
import { useApp } from '../contexts/app.context';
import { useAuth } from '../contexts/auth.context';
import { GET_ALL_CUSTOMERS } from '../services/customers';
import { ACCEPT_JOB, ARCHIVE_JOB, CHANGE_JOB_RESPONSIBLE, DECLINE_JOB, DELETE_JOB, GET_ALL_JOBS } from '../services/jobs';
import { getExtenseDatetime, useQuery } from '../utility/util';
import { GET_ALL_USERS } from '../services/users';
import { useMediaQuery } from '@mantine/hooks';
import JobPage, { JobKanban, JobPaper } from './job';
import moment from 'moment';

export default function JobsPage(){
    const [items, setItems] = React.useState<any[]>([]);
    const [customers, setCustomers] = React.useState<any[]>([]);
    const [users, setUsers] = React.useState<any[]>([]);
    const [loadingItems, setLoadingItems] = React.useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = React.useState({});
    const [openDone, setOpenDone] = React.useState(false);
    const [openCustomer, setOpenCustomer] = React.useState(false);
    const [marginLeft, setMarginLeft] = React.useState(0);

    const setLoadingRef = useRef(true);

    const { orderId } = useParams();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const containerRef = useRef<any>(null);

    const { role, user: currentUser } = useAuth();
    const query = useQuery();
    const customerId = role?.profile?.title === "Client" ? currentUser._id : query.get("customer");

    const navigate = useNavigate();
    const { setNewOrder, confirmDialog} = useApp();

    const selectedCustomer = customers.find(c => customerId === c._id)

    const loadItems = (setLoading = true) => {
        setLoadingItems(setLoading)
        GET_ALL_JOBS()
        .then(res => {
            setLoadingRef.current = false;
            setItems(res);
            setLoadingItems(false)
        })
        .catch(err => {
            setLoadingItems(false)
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }
    
    const loadUsers = () => {
        if(role){
            GET_ALL_USERS()
            .then(res => {
                setUsers(res.filter(r => r.profile?.title !== "Client"))
                setCustomers(res.filter(r => r.profile?.title === "Client"))
            })
            .catch(err => {
                notifications.show({title: "Ops", message: err.message, color: 'red'})
            })
        }
    }

    const changeResponsible = (orderId, user) => {
        let data = items.find(item => item.id === orderId);
        const active = !(data?.responsibles || []).some(r => r._id === user._id);
        let previous = items;
        setItems(items => items.map(d => d.id === orderId
            ? {
                ...d,
                responsibles: active
                    ? [...d.responsibles, user]
                    : (d.responsibles || []).filter(r => r._id !== user._id)
            } : d)
        )
        CHANGE_JOB_RESPONSIBLE(orderId, user._id, active)
        .then(() => {})
        .catch(err => {
            setItems(previous);
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const renderItem = (item, index) => {
        if(role?.profile?.title === "Client") return <UnstyledButton style={{width: '100%'}} onClick={() => navigate(`/orders/${item.id}`)}>
            <Paper p="lg" shadow='xs' style={{border: '1px solid black'}} mb="xl">
                <Group pl="lg" pr="lg">
                    <FaCalendarDay color="gray" />
                    <Text style={{flex: 1}} size="sm" c="gray">{getExtenseDatetime(item.deadline || item.createdAt)}</Text>
                </Group>
                <Box pl="lg" pr="lg" pt="sm">
                    <Text size="md" fw="bold">{item.name}</Text>
                    <Text size="sm" c="gray">{item.description}</Text>
                </Box>
            </Paper>
        </UnstyledButton>
        if(!item.status) return <JobPaper
            data={item}
            statusOptions={[]}
            onAccepted={loadItems}
            onDeclined={() => setItems(i => i.filter(j => j.id !== item.id))}
        />
        return <UnstyledButton
            style={{width: "100%"}}
            onClick={() => {
                navigate(role ? `/orders/${item.id}` : `/orders/${item.id}`)
            }}
        >
            <JobKanban order={item} />
        </UnstyledButton>

    }

    const getItems = (status, archived = false) => (
        items
        .filter(i => 
            (archived ? !!i.archived : !i.archived) &&
            (!customerId || i.customer?._id === customerId) &&
            (i.statusKey === status)
        )
        .sort((a,b) => a.last_update > b.last_update ? -1 : 1)
    )

    const [isDragging, setIsDragging] = useState(false);
    const [dragStartX, setDragStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setDragStartX(e.clientX);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const deltaX = e.clientX - dragStartX;
        const newV = scrollLeft - deltaX;
        const scrollPosition = containerRef.current.scrollLeft;
        const scrollSize = containerRef.current.scrollWidth - containerRef.current.offsetWidth;
        setScrollLeft(newV > 0 ? scrollSize < newV ? scrollSize : newV : 0);
        setDragStartX(e.clientX);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };
    
    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
          document.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    useEffect(() => {
        if(containerRef.current) containerRef.current.scrollLeft = scrollLeft;
    }, [scrollLeft, containerRef.current])

    useEffect(() => {
        loadItems(setLoadingRef.current);
    }, [orderId]);
    useEffect(loadUsers, [role]);

    return <div style={{position: 'relative'}}>
        <SimpleHeader
            title={role?.profile?.title !== "Client" ? "Orders" : "Your orders"}
            subtitle={role?.profile?.title !== "Client" ? "Manage all your orders in one place." : "All your orders in one place."}
            // right={role?.profile?.title !== "Client" && <Menu position='bottom' onOpen={() => setOpenCustomer(true)} closeOnEscape opened={openCustomer} onClose={() => setOpenCustomer(false)}>
            //     <Menu.Target>
            //         {selectedCustomer
            //             ? <UnstyledButton><CustomerAvatar size="md" c="gray" name={selectedCustomer.name} image={selectedCustomer.image} /></UnstyledButton>
            //             : <Button size="sm" variant='filled' color="gray" className='draw-button' leftSection={<FaUser/>}>Filter by Client</Button>}
            //     </Menu.Target>
            //     <Menu.Dropdown style={{maxWidth: 300, minWidth: 300}}>
            //         <Menu.Item onClick={() => {
            //             setOpenCustomer(false);
            //             navigate(`/orders`)
            //         }}>All Clients</Menu.Item>
            //         <Menu.Divider></Menu.Divider>
            //         {customers
            //         .filter(c => c._id !== customerId)
            //         .map(customer =>
            //             <UnstyledButton
            //                 style={{width: "100%"}}
            //                 onClick={() => {
            //                     navigate(`/orders?customer=${customer._id}`);
            //                     setOpenCustomer(false)
            //                 }}
            //                 p="sm"
            //             >
            //                 <CustomerAvatar
            //                     name={customer.name}
            //                     image={customer.image}
            //                 />
            //             </UnstyledButton>
            //         )}
            //     </Menu.Dropdown>
            // </Menu>}
            buttons={[
                // { title: "Arquivados", color: "gray", leftSection: <Text c="white" fw="bold">({getItems(null, true).length})</Text>, onClick: () => setOpenDone(true) },
                // role && {title: "New Order", leftSection: <FaPlus />, onClick: () => {setNewOrder({})}}
            ].filter(nn => nn)}  
        />

        <LoadingOverlay visible={loadingItems} />
        
        {role?.profile?.title !== "Client"
        ? <div
            className="scrollbar-hidden"
            ref={containerRef}
            style={{
                overflowX: 'scroll',
                userSelect: 'none',
                cursor: "grab",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: isMobile ? 'calc(80vw * 4)' : 'calc(120vw - 250px)', marginLeft: marginLeft}}>
                <div style={{flex: 1}}>
                    <Title order={4}>Pending Approval</Title>
                    <div style={{height: 20, background: '#E0E0E0', marginBottom: 10, marginTop: 10, borderBottom: '1px solid #CACACA', borderTop: '1px solid #CACACA'}}></div>
                    <Box p="md" style={{height: '65vh', background: '#EFEFEF', borderRadius: 8, overflowY: 'scroll'}} m="xs">
                        {getItems(undefined).map(renderItem)}
                    </Box>
                </div>
                <div style={{flex: 1}}>
                    <Title order={4}>Backlog</Title>
                    <div style={{height: 20, background: '#FFFFFF', marginBottom: 10, marginTop: 10, borderBottom: '1px solid #CACACA', borderTop: '1px solid #CACACA'}}></div>
                    <Box p="md" style={{height: '65vh', background: '#EFEFEF', borderRadius: 8, overflowY: 'scroll'}} m="xs">
                        {getItems("backlog").map(renderItem)}
                    </Box>
                </div>
                <div style={{flex: 1}}>
                    <Title order={4}>In progress</Title>
                    <div style={{height: 20, background: '#fed540', marginBottom: 10, marginTop: 10, borderBottom: '1px solid #CACACA', borderTop: '1px solid #CACACA'}}></div>
                    <Box p="md" style={{height: '65vh', background: '#EFEFEF', borderRadius: 8, overflowY: 'scroll'}} m="xs">
                        {getItems("in-progress").map(renderItem)}
                    </Box>
                </div>
                <div style={{flex: 1}}>
                    <Title order={4}>Completed</Title>
                    <div style={{height: 20, background: '#04db72', marginBottom: 10, marginTop: 10, borderBottom: '1px solid #CACACA', borderTop: '1px solid #CACACA'}}></div>
                    <Box p="md" style={{height: '65vh', background: '#EFEFEF', borderRadius: 8, overflowY: 'scroll'}} m="xs">
                        {getItems("completed").map(renderItem)}
                    </Box>
                </div>
            </div>
        </div>
        : <div>
            <Title order={4}>In progress</Title>
            <Box mt="md" mb="md">
                {getItems("in-progress").length === 0 && <Text mb="md" mt="md">No item.</Text>}
                {getItems("in-progress").map(renderItem)}
            </Box>
            <Divider mt="xl" mb="xl" />
            <Title order={4}>Backlog</Title>
            <Box mt="md" mb="md">
                {getItems("backlog").length === 0 && <Text mb="md" mt="md">No item.</Text>}
                {getItems("backlog").map(renderItem)}
            </Box>
            <Divider mt="xl" mb="xl" />
            <Title order={4}>Waiting approval</Title>
            <Box mt="md" mb="md">
                {getItems(undefined).length === 0 && <Text mb="md" mt="md">No item.</Text>}
                {getItems(undefined).map(renderItem)}
            </Box>
            <Divider mt="xl" mb="xl" />
            <Title order={4}>Completed</Title>
            <Box mt="md" mb="md">
                {getItems('completed').length === 0 && <Text mb="md" mt="md">No item.</Text>}
                {getItems('completed').map(renderItem)}
            </Box>
        </div>}
        
        <Modal
            opened={openDone}
            onClose={() => { setOpenDone(false) }}
            size="sm"
        >
            {getItems(null, true).map(renderItem)}
        </Modal>
    </div>
}