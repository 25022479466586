
import { ActionIcon, Button, Grid, Group, Paper } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React from 'react';
import { UPDATE_COMPANY_FIELDS } from '../services/companies';
import { GET_USER_DATA } from '../services/users';
import { FaPlus, FaTrash } from 'react-icons/fa';
import InputField, { InputFieldTypes } from '../components/input_field';

export default function FieldsPage(){
    const [user, setUser] = React.useState<any>(null);
    const [company, setCompany] = React.useState<any>(null);
    const [loadingSave, setLoadingSave] = React.useState(false);

    const handleSave = () => {
        setLoadingSave(true)
        UPDATE_COMPANY_FIELDS(company?.customFields)
        .then(() => {
            setLoadingSave(false)
            notifications.show({ message: "Profile updated", color: "green" })
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    const loadData = () => {
        GET_USER_DATA()
        .then(({user, company}) => {
            setUser(user)
            setCompany(company)
        })
        .catch(err => {
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    const handleChangeField = index => dt => {
        setCompany(c => ({
            ...c,
            customFields: c.customFields.map((cf, j) => index === j ? {...cf, ...dt} : cf)
        }))
    }

    React.useEffect(() => { loadData() }, [])

    return <>
        <Group justify="flex-end" mt="md">
            <Button size="sm" leftSection={<FaPlus />}
                color="gray"
                variant='outline'
                onClick={() => setCompany(c => ({...c, customFields: [...(c.customFields||[]), { type: "string" }]}))}>Add Field</Button>
        </Group>

        {(company?.customFields || []).map((field, i) => <Paper p="md" shadow='xs' mb="xs" mt="xs">
            <Grid align='flex-end'>
                <Grid.Col span={{base: 12, sm: 4}}>
                    <InputField
                        title="Type"
                        name="type"
                        fieldType={InputFieldTypes.SELECT}
                        options={[
                            { label: "Text", value: "string" },
                            { label: "Date", value: "date" },
                            { label: "Datetime", value: "datetime" },
                            { label: "Time", value: "time" },
                        ]}
                        value={field.type}
                        onChange={handleChangeField(i)}
                    />
                </Grid.Col>
                <Grid.Col span={{base: 12, sm: 4}}>
                    <InputField
                        title="Title"
                        name="title"
                        value={field.title}
                        onChange={handleChangeField(i)}
                    />
                </Grid.Col>
                <Grid.Col span={{base: 12, sm: 3}}></Grid.Col>
                <Grid.Col span={{base: 12, sm: 1}}>
                    <ActionIcon color="gray" variant='light' onClick={() => setCompany(c => ({...c, customFields: (c.customFields||[]).filter((c,j) => i !== j) }))}>
                        <FaTrash />
                    </ActionIcon>
                </Grid.Col>
            </Grid>
        </Paper>)}

        <Group justify="flex-end" mt="md">
            <Button size="md" onClick={handleSave} loading={loadingSave} style={{background: 'black'}}>Save Custom Fields</Button>
        </Group>
    </>
}
