import { Button, Group, LoadingOverlay, Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import InputField from '../components/input_field';
import SimpleHeader from '../components/simple_header';
import Table from '../components/table';
import { useApp } from '../contexts/app.context';
import { SEND_INVITE } from '../services/companies';
import { GET_ALL_PROFILES } from '../services/profiles';
import { GET_ALL_USERS } from '../services/users';
import { useLanguage } from '../contexts/language.context';
import { SAVE_CUSTOMER } from '../services/customers';
import { FORGOT_PASSWORD } from '../services/auth';

export default function CustomersPage(){
    const [items, setItems] = React.useState<any[]>([]);
    const [modal, setModal] = React.useState<any>(null);
    const [loadingItems, setLoadingItems] = React.useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = React.useState({});
    const [searchText, setSearchText] = React.useState("");
    const [profiles, setProfiles] = React.useState<any[]>([]);

    const customerProfile = (profiles ?? []).find(p => p.title === "Client");

    const app = useApp();
    const navigate = useNavigate();
    const { str } = useLanguage();

    const loadItems = () => {
        setLoadingItems(true)
        GET_ALL_USERS()
        .then(res => {
            setItems(res.filter(r => r.profile?.title === "Client"));
            setLoadingItems(false)
        })
        .catch(err => {
            setLoadingItems(false)
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const handleInvite = (user) => {
        SEND_INVITE({ name: user.name, email: user.email, profile: customerProfile._id })
        .then(res => {
            notifications.show({ message: str("INVITE_SENT") })
        })
        .catch(err => {
            notifications.show({message: err.message, color: 'red'})
        })
    }
    
    const handleResetPassword = (user) => {
        if(!user.claimed){
            notifications.show({ message: `This account was not claimed yet, first send an invite to user.` })
        }else{
            app.confirmDialog({ text: "Send an e-mail with reset password instructions" }, ({confirmed}) => {
                if(confirmed) {
                    FORGOT_PASSWORD(user.email)
                    .then(res => {
                        notifications.show({ message: `Instruction to recover password was sent to e-mail '${user.email}'` })
                    })
                    .catch(err => {
                        notifications.show({message: err.message, color: 'red'})
                    })
                }
            })
        }
    }

    const loadProfiles = () => {
        GET_ALL_PROFILES()
        .then(res => {
            setProfiles(res)
        })
        .catch(err => {
            notifications.show({message: err.message, color: 'red'})
        })
    }

    useEffect(loadProfiles, []);
    
    useEffect(loadItems, []);

    return <>
        <SimpleHeader
            title={str("CLIENTS.TITLE")}
            subtitle={str("CLIENTS.SUBTITLE")}
            // right={<Group>
            //     <InputField
            //         placeholder="Search"
            //         name="search"
            //         value={searchText}
            //         rightSection={<FaSearch c="#CACACA" />}
            //         variant="filled"
            //         onChange={({search}) => setSearchText(search)}
            //     />
            // </Group>}
            button={{ title: str("CLIENTS.ADD_CLIENT"), onClick: () => { setModal(true)}}}  />
        
        <LoadingOverlay visible={loadingItems} />

        <Table
            columns={[
                { title: str("NUMBER"), key: "No", render: (item, index) => `#${`00${index+1}`.slice(-4)}` },
                { title: str("CLIENT_NAME"), key: "name" },
                { title: str("COMPANY_BRAND"), key: "company_name" },
                // { title: "Location", key: "Location" },
                { title: str("PHONE_NUMBER"), key: "phone" },
                { title: str("EMAIL"), key: "email" },
                { title: str("WEBSITE"), key: "website" },
                // { title: "Plan", key: "Plan" },
            ]}
            data={items}
            actions={[
                { title: str("SEND_INVITE"), color: "orange", onClick: ({item}) => handleInvite(item) },
                { title: "Reset Password", color: "orange", onClick: ({item}) => handleResetPassword(item) },
                { title: str("DETAILS"), color: "orange", onClick: ({item}) => navigate(`/clients/${item._id}`) },
            ]}
        />

        <CustomerModal
            data={modal}
            opened={!!modal}
            profiles={profiles}
            onClose={() => setModal(null)}
            onSave={() => { loadItems(); navigate("/clients"); setModal(null) }}
        />
    </>
}

const CustomerModal = (props) => {
    const { data : dataParent = null, onClose, onSave, opened = false } = props;
    const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
    const [data, setData] = React.useState<any>(null);

    const { str } = useLanguage();

    const handleChange = dt => setData(d => ({...d, ...dt}));
    const handleChangeProps = dt => setData(d => ({...d, props: { ...d?.props, ...dt }}));

    const customerProfile = (props.profiles ?? []).find(p => p.title === "Client");

    const handleSave = () => {
        setLoadingSave(true)
        SAVE_CUSTOMER({...data, profile: customerProfile._id})
        .then(res => {
            setLoadingSave(false)
            onSave && onSave()
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({message: err.message, color: 'red'})
        })
    }

    useEffect(() => {
        if(opened) setData(d => ({approvers: [{}], ...dataParent}))
    }, [opened, dataParent])


    return <>
        <Modal
            opened={!!opened}
            onClose={onClose}
            size="lg"
        >
            <InputField
                mt="lg"
                mb="lg"
                name="name"
                title={str("CLIENT_NAME")}
                value={data?.name}
                onChange={handleChange}
            />
            <InputField
                mt="lg"
                mb="lg"
                name="email"
                title={str("EMAIL")}
                value={data?.email}
                onChange={handleChange}
            />
            
            <Group justify="flex-end" mt="xl">
                <Button variant='subtle' size="md" disabled={loadingSave} onClick={onClose}>{str("CANCEL")}</Button>
                <Button variant='filled' size="md" loading={!customerProfile || loadingSave} onClick={handleSave}>{str("SUBMIT")}</Button>
            </Group>
        </Modal>
    </>
}