import { ActionIcon, Box, Button, Divider, Grid, Group, Menu, Paper, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/auth.context';
import { CREATE_SUPPORT, SEND_SUPPORT_MESSAGE, GET_SUPPORT_DATA, UPDATE_SUPPORT } from '../services/supports';
import { FaArrowDown, FaCheck, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import MessagesBox from '../components/messages_box';
import InputField, { InputFieldTypes } from '../components/input_field';
import { GET_ALL_USERS } from '../services/users';
import { useMediaQuery } from '@mantine/hooks';

export default function SupportPage(props){
    const params = useParams();
    const supportId: string = props.supportId || params["supportId"] || "new";
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width: 900px)');

    const { role, user: currentUser } = useAuth();

    const [loadingData, setLoadingData] = React.useState<boolean>(false);
    const [updating, setUpdating] = React.useState<boolean>(false);
    const [loadingSave, setLoadingSave] = React.useState<boolean>(false);
    const [customers, setCustomers] = React.useState<any[]>([]);
    const [startingMessage, setStartingMessage] = React.useState<string>("");
    const [selectedCustomer, setSelectedCustomer] = React.useState<any>(null);
    const [data, setData] = React.useState<any>({});

    const loadData = () => {
        setLoadingData(true)
        GET_SUPPORT_DATA(supportId)
        .then((dt) => {
            setLoadingData(false)
            setData({...dt})
        })
        .catch(err => {
            setLoadingData(false)
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const loadUsers = () => {
        if(role){
            GET_ALL_USERS()
            .then(res => {
                setCustomers(res.filter(r => r.profile?.title === "Client"))
            })
            .catch(err => {
                notifications.show({title: "Ops", message: err.message, color: 'red'})
            })
        }
    }

    const handleSave = (params: any) => {
        setLoadingSave(true);
        (supportId === "new" ? CREATE_SUPPORT({ ...params }) : UPDATE_SUPPORT(supportId, {...params}))
        .then((res) => {
            setLoadingSave(false)
            notifications.show({ message: supportId === "new" ? "Support oppened." : "Support updated.", color: 'green'})
            setUpdating(true);
            props.onSave && props.onSave(res)
            if(supportId === "new") navigate(`/support/${res._id}`);
            if(supportId !== "new") loadData();
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const handleSendMessage = ({ text, files }) => {
        SEND_SUPPORT_MESSAGE(supportId, { text, files })
        .then(res => {
            loadData();
        })
        .catch(err => {
            notifications.show({title: "Ops", message: err.message, color: 'red'})
        })
    }

    const { FullComponent } = MessagesBox({
        onSubmit: handleSendMessage,
        messages: (data?.messages || []),
        allowMessage: data?.status !== "solved"
    });

    useEffect(() => {
        if(supportId !== "new"){ loadData() }
    }, [supportId])
    useEffect(() => {
        loadUsers()
    }, [])

    return <div style={{position: 'relative'}}>
        <Paper shadow='xs' style={{padding: 0, border: 0, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', minHeight: '80vh', position: 'relative'}}>
            <Box style={{background: '#FAFAFA', flex: 0.6}}>
                <Group p="xl" >
                    <Title style={{flex: 1}} order={4}>Messages</Title>
                    <ActionIcon size="lg" onClick={() => {
                        navigate("/support");
                        props.onClose && props.onClose()
                    }}><FaTimes /></ActionIcon>
                </Group>
                <Divider color="#E0E0E0" />
                <Box style={{height: '70vh'}}>
                    {supportId === "new"
                    ? <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <div style={{flex: 1}}></div>
                        <Box p="md">
                            {role?.profile?.title !== "Client" && <InputField
                                fullWidth
                                value={selectedCustomer}
                                fieldType={InputFieldTypes.SELECT}
                                onChange={({ c }) => setSelectedCustomer(c)}
                                name="c"
                                mb="md"
                                placeholder="Select the client"
                                options={customers.map(c => ({label: c.name, value: c._id}))}
                            />}
                            <InputField
                                fullWidth
                                value={startingMessage}
                                fieldType={InputFieldTypes.TEXTAREA}
                                minRows={1}
                                maxRows={4}
                                onChange={({ body }) => setStartingMessage(body ? body : "")}
                                name="body"
                                placeholder="Type your message"
                                autoComplete="off"
                            />
                            <Group justify="flex-end">
                                <Button mt="md" onClick={() => handleSave({ title: startingMessage, customer: selectedCustomer })}>Start support</Button>
                            </Group>
                        </Box>
                    </div>
                    : FullComponent}
                </Box>
            </Box>
            <Box p="xl" style={{flex: 0.4}}>
                <Title order={3}>{data?.category?.title || "Default support"}</Title>
                <Text size="sm" c="gray" mt="md">{data?.title}</Text>
                <Group justify="flex-end">
                    {data?.status === "pendent" && <Button mt="xl" onClick={() => handleSave({ status: "solved" })} rightSection={<FaCheck />} className='draw-button'>Close Ticket</Button>}
                    {data?.status === "solved" && <Button mt="xl" onClick={() => handleSave({ status: "pendent" })} className='draw-button'>Re-open Ticket</Button>}
                </Group>
            </Box>
        </Paper>
        
    </div>
}
