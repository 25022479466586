import { Avatar, Box, Button, Group, Switch, Text, Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { FaEnvelopeOpen } from "react-icons/fa";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../../services/companies";
import InputField, { InputFieldTypes } from "../input_field";

export default function Sendgrid(){
    const [data, setData] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [template, setTemplate] = useState<string>("");

    const loadData = () => {
        GET_COMPANY_INTEGRATION("sendgrid")
        .then((res) => {
            setData(res)
        })
        .catch(err => {
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        
        SET_COMPANY_INTEGRATION("sendgrid", {...data})
        .then((res) => {
            setLoadingSave(false);
            loadData()
            notifications.show({message: "Template ID Configured", color: 'green'})
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => {
        setIsValid(
            {...data}.api_key &&
            {...data}.from_email &&
            (!{...data}[template] || ({...data}[template] ?? "").includes("d-"))
        );
    }, [data]);

    React.useEffect(() => { loadData() }, [])

    return <>
        <Group>
            <Avatar size={40} src="/assets/sendgrid.png" />
            <Title style={{flex: 1}} order={5}>SendGrid</Title>
            <Switch onChange={() => {
                let enabled = !data?.enabled;
                setData(d => ({ ...d, enabled }));
                SET_COMPANY_INTEGRATION("sendgrid", {...data, enabled})
                .then((res) => {})
                .catch(err => { setData(d => ({ ...d, enabled: !enabled })); })
            }} label="Enabled" checked={data?.enabled} />
        </Group>

        <InputField
            mt="md"
            name="api_key"
            value={data?.api_key}
            title="API KEY"
            onChange={({api_key}) => { setData(u => ({ ...u, api_key })) }}
        />
        <Group grow mt="md">
            <InputField
                name="from_name"
                value={data?.from_name}
                title="From Name"
                onChange={({from_name}) => { setData(u => ({ ...u, from_name })) }}
            />
            <InputField
                name="from_email"
                value={data?.from_email}
                title="From Email"
                onChange={({from_email}) => { setData(u => ({ ...u, from_email })) }}
            />
        </Group>

        <InputField
            mt="md"
            name="template"
            value={template}
            title="Template"
            onChange={({template}) => setTemplate(template)}
            searchable={false}
            fieldType={InputFieldTypes.SELECT}
            options={[
                // { value: "forgot_password", label: "Forgot Password Mail" },
                // { value: "confirm_recover", label: "confirm_recover" },
                // { value: "verify_email", label: "verify_email" },
                // { value: "verified_email", label: "verified_email" },
                { value: "customer_invite", label: "Customer Invite" },
                { value: "user_invite", label: "Users Invite" },
                { value: "agency_new_job", label: "New Order Request" },
                { value: "change_responsible", label: "Responsible Changed for Order" },
                { value: "customer_order_accepted", label: "Order Accepted" },
                { value: "customer_order_declined", label: "Order Declined" },
                { value: "customer_order_message_receive", label: "New Agency Order Message" },
                { value: "agency_order_message_received", label: "New Customer Order Message" },
                { value: "customer_support_message_received", label: "New Agency Support Message" },
                { value: "agency_support_message_received", label: "New Customer Support Message" },
                { value: "verify_email", label: "E-mail validation" },
            ]}
        />
        <InputField
            mt="md"
            name="vl"
            value={{...data}[template]}
            title="Template ID"
            onChange={({vl}) => {
                setData(u => ({ ...u, [template]: vl }))
            }}
        />

        {exampleTexts[template] && <>
            <Text mt="md">Params send to SENDGRID:</Text>
            <Box style={{background: '#EFEFEF'}} p="md" mt="md">
                {exampleTexts[template].map(t => <><div style={{whiteSpace: 'pre-wrap'}}>{t}</div></>)}
            </Box>
        </>}

        <Group justify="flex-end" mt="md">
            <Button size="md" onClick={handleSave} disabled={!isValid} loading={loadingSave} color="green" className="draw-button">Save Updates</Button>
        </Group>
    </>
}

const exampleTexts = {
    customer_invite: [
        `{{logoImage}}`,
        `{{name}}`,
        `{{company}}`,
        `{{url}}`,
    ],
    verify_email: [
        `{{logoImage}}`,
        `{{name}}`,
        `{{url}}`,
    ],
    user_invite: [
        `{{logoImage}}`,
        `{{name}}`,
        `{{company}}`,
        `{{url}}`,
    ],
    agency_new_job: [
        `{{company}}`,
        `{{orderName}}`,
        `{{orderDate}}`,
        `{{orderTotal}}`,
        `{{url}}`,
        `{{details}}`,
    ],
    change_responsible: [
        `{{name}}`,
        `{{orderId}}`,
        `{{orderName}}`,
        `{{orderDescription}}`,
        `{{url}}`,
    ],
    customer_order_accepted: [
        `{{name}}`,
        `{{company}}`,
        `{{orderName}}`,
        `{{orderDate}}`,
        `{{orderTotal}}`,
        `{{url}}`,
        `{{paymentUrl}}`,
        `{{details}}`,
    ],
    customer_order_declined: [
        `{{name}}`,
        `{{company}}`,
        `{{orderName}}`,
        `{{orderDate}}`,
        `{{url}}`,
        `{{orderTotal}}`,
    ],
    customer_order_message_receive: [
        `{{logo_image}}`,
        `{{company}}`,
        `{{message}}`,
        `{{name}}`,
        `{{senderImage}}`,
        `{{senderName}}`,
        `{{orderName}}`,
        `{{orderDate}}`,
        `{{orderTotal}}`,
        `{{url}}`,
        `{{details}}`,
    ],
    agency_order_message_received: [
        `{{logo_image}}`,
        `{{company}}`,
        `{{message}}`,
        `{{name}}`,
        `{{senderImage}}`,
        `{{senderName}}`,
        `{{orderName}}`,
        `{{orderDate}}`,
        `{{orderTotal}}`,
        `{{url}}`,
        `{{details}}`,
    ],
    customer_support_message_received: [
        `{{logo_image}}`,
        `{{company}}`,
        `{{message}}`,
        `{{name}}`,
        `{{senderImage}}`,
        `{{senderName}}`,
        `{{orderName}}`,
        `{{orderDate}}`,
        `{{url}}`,
    ],
    agency_support_message_received: [
        `{{logo_image}}`,
        `{{company}}`,
        `{{message}}`,
        `{{name}}`,
        `{{senderImage}}`,
        `{{senderName}}`,
        `{{orderName}}`,
        `{{orderDate}}`,
        `{{url}}`,
    ],
}