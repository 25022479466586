import { ActionIcon, Box, Button, Divider, Grid, Group, Image, Modal, Paper, Progress, Text, Title, UnstyledButton } from "@mantine/core";
import { notifications, showNotification } from "@mantine/notifications";
import React, { useEffect, useRef, useState } from "react";
import InputField, { InputFieldMasks, InputFieldTypes } from "../components/input_field";
import { useApp } from "../contexts/app.context";
import { FINISH_KNOWLEDGE, GET_ALL_KNOWLEDGES, GET_KNOWLEDGE_DETAILS } from "../services/knowledges";
import { FaArrowLeft, FaCheckCircle, FaTimes } from "react-icons/fa";
import RenderImage from "../components/render_image";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { useMediaQuery } from "@mantine/hooks";

export default function KnowledgesPage(){
    const [knowledges, setKnowledges] = useState<any[]>([]);
    const [selectedKnowledgeCategory, setSelectedKnowledgeCategory] = useState<any>(null);
    const [selectedKnowledge, setSelectedKnowledge] = useState<any>(null);

    const topRef = useRef<any>()

    const { confirmDialog } = useApp();

    const isMobile = useMediaQuery('(max-width: 900px)');

    const loadKnowledges = () => {
        GET_ALL_KNOWLEDGES()
        .then(res => {
            setKnowledges(res);
        })
        .catch(err => {
            notifications.show({ message: err.message, color: "red" })
        })
    }
    
    const loadKnowledgeDetails = () => {
        if(selectedKnowledge){
            GET_KNOWLEDGE_DETAILS(selectedKnowledge._id)
            .then(res => {
                // setKnowledges(res);
            })
            .catch(err => {
                notifications.show({ message: err.message, color: "red" })
            })
        }
    }

    const finishKnowledge = (kn) => {
        if(kn){
            FINISH_KNOWLEDGE(kn._id)
            .then(() => {
                setSelectedKnowledgeCategory(kc => kc
                    ? ({
                        ...kc,
                        items: (kc?.items ?? []).map(k => (
                            k._id === kn._id
                            ? { ...k, finished_at: moment().format() }
                            : k
                        ))
                    })
                    : kc
                )
                setKnowledges(k => k.map(item => ({...item, items: (item.items ?? []).map(k => (
                    k._id === kn._id
                    ? { ...k, finished_at: moment().format() }
                    : k
                ))})))
            })
            .catch(err => {
                showNotification({message: err.message})
            })
        }
    }

    useEffect(() => {
        window.scroll({ top: 0,  behavior: 'smooth' });
    }, [topRef.current, selectedKnowledgeCategory?._id])

    useEffect(loadKnowledges, []);
    useEffect(loadKnowledgeDetails, [selectedKnowledge]);
    
    return <div ref={topRef}>
        {
            selectedKnowledgeCategory 
            && <Group mb="md">
                <ActionIcon variant="outline" onClick={() => setSelectedKnowledgeCategory(null)}><FaArrowLeft /></ActionIcon>
                <Title order={4} c="orange">{selectedKnowledgeCategory.title}</Title>
            </Group>
            // : <Group mb="md">
            //     <Title order={4} c="orange">Retainr Accelerator</Title>
            // </Group>
        }
            {selectedKnowledgeCategory
            ? <>
                <Grid pt="md" pr="md">
                    {
                        (selectedKnowledgeCategory?.items ?? [])
                        .sort((a,b) => a.title > b.title ? 1 : -1)
                        .map(item => (
                            <Grid.Col span={{base: 12, md: 3}}>
                            <UnstyledButton style={{width: '100%'}} onClick={() => setSelectedKnowledge(item)}>
                                <Paper p="xs" mb="md" style={{borderColor: '#DFDFDF', position: 'relative', borderWidth: item._id === selectedKnowledge?._id ? 3 : 1}}>
                                    <Group style={{position: 'absolute', padding: 6, borderRadius: 50, top: -15, right: -15, background: 'white', border: '1px solid #DFDFDF'}} justify="flex-end" c={item.finished_at ? "green" : "gray"}><FaCheckCircle /></Group>
                                    <Title p="md" mt="xs" order={5} style={{height: 64}} className="max-lines-2">{item.title}</Title>
                                    <Box p="md"><img src={item.image} style={{width: '100%'}} /></Box>
                                </Paper>
                            </UnstyledButton>
                            </Grid.Col>
                        ))
                    }
                </Grid>
            </>
            : <Grid>
                {
                    (knowledges ?? [])
                    .sort((a,b) => a.title > b.title ? 1 : -1)
                    .map(item => (
                        <Grid.Col span={{base: 12, md: 3}}>
                            <UnstyledButton style={{width: '100%'}} onClick={() => setSelectedKnowledgeCategory(item)}>
                                <Paper p="xs" mb="md" style={{borderColor: '#DFDFDF', height: 340 }}>
                                    <Box p="md" style={{textAlign: 'center'}}><img src={item.image} style={{height: '120px'}} /></Box>
                                    <Title ta="center" mb="md" order={4}>{item.title}</Title>
                                    <Progress
                                        m="md"
                                        size="sm"
                                        color="green"
                                        value={(item.items ?? []).length
                                            ? 100 * ((item.items ?? []).filter(i => i.finished_at).length / (item.items ?? []).length)
                                            : 100}
                                    />
                                    <Text c="gray" size="sm" ta="center" mb="md">{item.description}</Text>
                                </Paper>
                            </UnstyledButton>
                        </Grid.Col>
                    ))
                }
            </Grid>}

        {<Paper
            shadow="xs"
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 999,
                transition: '0.6s all',
                left: selectedKnowledge ? isMobile ? 0 : '35vw' : '100vw',
                background: 'white',
                borderRadius: 0,
                borderColor: '#DFDFDF',
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
            }}
        >
            <Box p="xl">
                <Group align="flex-start">
                    <Title style={{flex: 1}} order={3} mb="mt">{selectedKnowledge?.title}</Title>
                    <ActionIcon color="gray" variant="outline" onClick={() => {
                        setSelectedKnowledge(null);
                    }}><FaTimes /></ActionIcon>
                </Group>
            </Box>
            <div style={{overflowY: 'auto', flex: 1, padding: 20}} className="format-custom-html">
                {ReactHtmlParser(selectedKnowledge?.body)}
            </div>
            <Divider />
            <Group p="md">
                <Button onClick={() => {
                    finishKnowledge(selectedKnowledge);
                    let catIndex = knowledges.findIndex((k) => k._id === selectedKnowledgeCategory?._id);
                    let itemIndex = selectedKnowledgeCategory.items.findIndex((i) => (i._id === selectedKnowledge?._id));
                    let nextItem = selectedKnowledgeCategory.items[itemIndex + 1] ?? (knowledges[catIndex + 1]?.items ?? [])[0];
                    let nextCategory = selectedKnowledgeCategory.items[itemIndex + 1] ? selectedKnowledgeCategory : knowledges[catIndex + 1];
                    setSelectedKnowledgeCategory(nextCategory);
                    setSelectedKnowledge(nextItem);
                }}>Next</Button>
            </Group>
        </Paper>}
    </div>
}
