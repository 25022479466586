import { ActionIcon, Box, Checkbox, ColorInput, Group, Indicator, Loader, MantineSize, MultiSelect, PasswordInput, SegmentedControl, Select, Text, Textarea, TextInput, useMantineTheme } from '@mantine/core';
import { DatePickerInput, DateTimePicker, TimeInput } from '@mantine/dates';
import { Dropzone, IMAGE_MIME_TYPE, MIME_TYPES, MS_EXCEL_MIME_TYPE } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FaCheckCircle, FaEye, FaFileImage, FaMinus, FaPlus, FaTimesCircle, FaUpload } from 'react-icons/fa';
import InputMask from "react-input-mask";
import NumberFormat from 'react-number-format';
import { UPLOAD_FILES } from '../services/files';
import { groupBy } from '../utility/util';

export enum InputFieldTypes{
    STRING = "string",
    NUMBER = "number",
    SELECT = "select",
    CHECKBOX = "checkbox",
    TRANSFER = "transfer",
    IMAGE = "image",
    FILE = "file",
    DATE = "date",
    DATETIME = "datetime",
    QUANTITY = "quantity",
    TIME = "time",
    DATERANGE = "daterange",
    BOOLEAN = "boolean",
    EXCEL = "excel",
    TEXTAREA = "textarea",
    BOX = "box",
    COLOR = "color",
    PASSWORD = "password",
}
export interface InputFieldProps{
    name:string
    title?:string;
    size?: MantineSize;
    value?: any;
    onChange?: any;
    loading?: boolean;
    min?: number;
    max?: number;
    options?: {value:string; label:string}[];
    fieldType?: InputFieldTypes;
    multiple?: boolean;
    mask?: InputFieldMasks;
    customMask?: string | string[];
    maskPlaceholder?: string;
    maskChar?: string;
    icon?: any;
    style?: any;
    styles?: any;
    variant?: string;
    minRows?: number;
    maxRows?: number;
    placeholder?: string;
    searchable?: boolean;
    clearable?: boolean;
    beforeMaskValueChange?: any;

    [x:string]: any;
}
export enum InputFieldMasks{
    MONEY = "money",
    CUSTOM = "custom",
}
export default function InputField(props : InputFieldProps){
    const {
        name,
        value,
        mask,
        customMask,
        maskPlaceholder = " ",
        maskChar = " ",
        title,
        description,
        fieldType = InputFieldTypes.STRING,
        loading,
        onChange,
        options = [],
        minRows = 5,
        maxRows = 8,
        multiple,
        icon,
        style,
        placeholder,
        clearable = true,
        styles,
        beforeMaskValueChange,
        dimensions = null,
        variant = "filled",
        size = "md",
        min = null,
        max = null,
        ...others
    } = props; 
    const [files, setFiles] = React.useState([]);
    const theme = useMantineTheme();

    const [loadingUpload, setLoadingUpload] = React.useState(false);

    const onInsertFiles = (items = []) => {
        setLoadingUpload(true)
        UPLOAD_FILES(items)
        .then(res => {
            console.log({[name]: multiple ? res.map(({url}) => url) : res[0] ? res[0].url : null})
            setLoadingUpload(false)
            setFiles(res.map(({url}) => url))
            onChange && onChange({[name]: multiple ? res.map(({url}) => url) : res[0] ? res[0].url : null}); 
        })
        .catch(err => {
            setLoadingUpload(false)
            notifications.show({title: "Failed to save file", message: err.message, color: 'red'})
        })
    }

    useEffect(() => {
        if([InputFieldTypes.QUANTITY].includes(fieldType) && min){
            onChange({ [name]: min })
        }
    }, [fieldType, min, name])
    
    if(fieldType === InputFieldTypes.COLOR){
        return <ColorInput
            label={title}
            value={value ?? undefined}
            title={title}
            placeholder={placeholder ?? title}
            style={style}
            styles={styles}
            size={size}
            variant={variant}
            onChange={(color) => onChange && onChange({[name]: color})}
            {...others}
        />
    }
    if([InputFieldTypes.QUANTITY].includes(fieldType)){
        return <>
            {title && <Text size="sm">{title}</Text>}
            <Group {...others} align='center'>
                <ActionIcon disabled={min && parseInt(value) <= min} size={"sm"} color='gray' variant='filled' onClick={() => {
                    if(min && (parseInt(value) - 1) <= min) onChange({[name]: min});
                    else onChange({[name]: value ? parseInt(value) - 1 : 0});
                }}><FaMinus size="12px" /></ActionIcon>
                <Box style={{width: 100}}>
                    <InputField
                        name="vl"
                        value={value || "0"}
                        type="number"
                        size={"xs"}
                        placeholder={placeholder ?? title}
                        onChange={({vl}) => {
                            vl = isNaN(vl) ? 0 : parseInt(vl || "0");
                            if(min && parseInt(vl) <= min) onChange({[name]: min});
                            else if(max && parseInt(vl) >= max) onChange({[name]: max});
                            else onChange({[name]: parseInt(vl)});
                        }}
                        readOnly
                        {...others}
                        style={{...style, cursor: 'pointer', fontWeight: 'bold'}}
                        styles={{input: {textAlign: 'center'}}}
                    />
                    {others.centerSection}
                </Box>
                <ActionIcon disabled={max && parseInt(value) >= max} size={"sm"} color='gray' variant='filled' onClick={() => {
                    if(max && (parseInt(value) + 1) >= max) onChange({[name]: max});
                    else onChange({[name]: value ? parseInt(value) + 1 : 1});
                }}><FaPlus size="12px" /></ActionIcon>
            </Group>
        </>
    }
    if([InputFieldTypes.CHECKBOX].includes(fieldType)){
        return <Checkbox
            label={title}
            checked={!!value}
            onChange={() => onChange({[name]: !value})}
            style={{...style, cursor: 'pointer', fontWeight: 'bold'}}
            {...others}
        />
    }
    if([InputFieldTypes.BOX].includes(fieldType)){
        return <Box {...others}>
            {title && <Text size="sm" fw="bold">{title}</Text>}
            {placeholder && <Text size="sm" c="gray">{placeholder}</Text>}
            <SegmentedControl
                value={value ? value : null}
                onChange={(vl) => onChange({[name]: vl})}
                data={groupBy(options ?? [], opt => opt.value).map(g => g[1][0])}
                fullWidth
                color="orange"
            />
        </Box>
    }
    if([InputFieldTypes.SELECT].includes(fieldType)){
        return multiple
        ? <MultiSelect    
            label={title}
            value={value ? value : []}
            placeholder={placeholder || "Select..."}
            searchable
            data={groupBy(options ?? [], opt => opt.value).map(g => g[1][0]).map(opt => ({label: `${opt.label}`, value: `${opt.value}`}))}
            clearable={clearable}
            size={size}
            variant={variant}
            onChange={(v) => onChange({[name]: v})}
            style={style}
            {...others}
        />
        : <Select
            value={value ? `${value}` : null}
            // label={value ? title : null}
            label={title}
            placeholder={placeholder ?? "Select..."}
            searchable
            size={size}
            variant={variant}
            data={groupBy(options ?? [], opt => opt.value).map(g => g[1][0]).map(opt => ({label: `${opt.label}`, value: `${opt.value}`}))}
            clearable={clearable}
            onChange={(v) => onChange({[name]: v})}
            style={style}
            {...others}
            autoFocus={false}
            // sx={{ ["& .mantine-Select-label"]: { fontWeight: "bold" } }}
        />
    }
    if([InputFieldTypes.DATE].includes(fieldType)){
        return <div>
            <DatePickerInput
                label={title}
                value={value ? value : null}
                placeholder={placeholder ?? "Select..."}
                closeOnChange
                valueFormat="DD/MM/YYYY"
                // dateParser={(dateString) => moment(dateString, "DD/MM/YYYY").toDate()}
                clearable={clearable}
                // allowFreeInput
                size={size}
                variant={variant}
                onChange={(v) => onChange({[name]: v})}
                {...others}
            />
        </div>
    }
    if([InputFieldTypes.DATETIME].includes(fieldType)){
        return <div>
            <DateTimePicker
                label={title}
                value={value ? value : null}
                placeholder={placeholder ?? "Select..."}
                valueFormat="DD/MM/YYYY HH:mm"
                // dateParser={(dateString) => moment(dateString, "DD/MM/YYYY").toDate()}
                clearable={clearable}
                // allowFreeInput
                onChange={(v) => onChange({[name]: v})}
            />
        </div>
    }
    if([InputFieldTypes.TIME].includes(fieldType)){
        return <div>
            <TimeInput
                label={title}
                value={value ? value : null}
                placeholder={placeholder ?? "Select..."}
                onChange={(v) => onChange({[name]: v.target.value})}
            />
        </div>
    }
    if([InputFieldTypes.DATERANGE].includes(fieldType)){
        return <div style={{position: 'relative'}}>
            <DatePickerInput
                allowSingleDateInRange
                label={title}
                closeOnChange
                value={(value && value.length > 0) ? value : [null, null]}
                placeholder={placeholder ?? "Select a period"}
                clearable={clearable}
                valueFormat="DD/MM/YYYY"
                type="range"
                renderDay={(date) => (
                    <Indicator size={6} color="red" offset={8} disabled={moment().format("YYYY-MM-DD") !== moment(date).format("YYYY-MM-DD")}>
                        <div>{date.getDate()}</div>
                    </Indicator>
                )}
                onChange={(v: any) => {
                    // const qt = v.filter(v => v).length;
                    onChange({[name]: v})
                }}
                {...others}
            />
        </div>
    }
    if([InputFieldTypes.BOOLEAN].includes(fieldType)){
        return <Checkbox
            label={title}
            checked={value ? ["true", true].includes(value) : null}
            onChange={(v) => onChange({[name]: !!v.target.checked})}
        />
    }
    if([InputFieldTypes.IMAGE, InputFieldTypes.FILE, InputFieldTypes.EXCEL].includes(fieldType)){
        return <div style={{position: 'relative'}}>
            {title && <Text fw="bold" style={{fontSize: 14, marginBottom: 4}}>{title}</Text>}
            {description && <Text style={{fontSize: 12, marginBottom: 4}}>{description}</Text>}
            <Dropzone
                onDrop={(files) => {
                    onInsertFiles(files)
                }}
                accept={
                    others.accept ||
                    {
                        [InputFieldTypes.IMAGE]: IMAGE_MIME_TYPE,
                        [InputFieldTypes.EXCEL]: MS_EXCEL_MIME_TYPE,
                    }[fieldType] || [
                        MIME_TYPES.csv, MIME_TYPES.doc, MIME_TYPES.doc, MIME_TYPES.docx,
                        MIME_TYPES.exe, MIME_TYPES.gif, MIME_TYPES.jpeg, MIME_TYPES.mp4,
                        MIME_TYPES.pdf, MIME_TYPES.png, MIME_TYPES.ppt, MIME_TYPES.svg,
                        MIME_TYPES.xls, MIME_TYPES.xlsx, MIME_TYPES.zip, MIME_TYPES.webp,
                    ]
                }
                multiple={multiple}
                style={{ margin: 0, padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', ...style }}
                maxSize={2000000}
                loading={loading}
                mt="lg"
                {...others}
            >
                <Group
                    align="center"
                    style={{ pointerEvents: "none" }}
                >
                    <Dropzone.Accept>
                        <FaUpload size={35} />
                        {((!!value && (!multiple || value.length > 0)) || files.length > 0) && (
                        <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                            <FaCheckCircle size={35} />
                            <div style={{padding: '0 20px'}}>
                                <Text size="md" inline>
                                    File uploaded
                                </Text>
                                <Text size="sm" c="dimmed" inline mt={7}>
                                    {value?.name || title}
                                </Text>
                            </div>
                        </div>)}
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <FaTimesCircle size={35} />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        {(!!value && (!multiple || value.length > 0))
                            ? multiple
                                ? <Group>
                                    {value.map(v => <img style={{height: '200px', width: `160px`, objectFit: 'contain'}} src={v} />)}
                                </Group>
                                : <img style={{height: '200px', width: '100%', objectFit: 'contain'}} src={value} />
                            : <div style={{flexDirection: 'row', padding: 20, display: 'flex', alignItems: 'center'}}>
                                <FaFileImage size={35} />
                                <div style={{flex: 1, padding: '0 20px'}}>
                                    <Text size="md" inline>Drag or click here to select a file</Text>
                                    <Text size="xs" c="dimmed" inline mt={7}>The file can't exceed 2MB</Text>
                                </div>
                            </div>
                        }
                    </Dropzone.Idle>
                </Group>
            </Dropzone>
            {!!loadingUpload
            ? <ActionIcon
                size='xl'
                color="orange"
                variant="filled"
                style={{position: 'absolute', bottom: 5, right: 5}}
            ><Loader size="xs" color="white" /></ActionIcon>
            : !!value && (!multiple || value.length > 0) && <ActionIcon
                size='xl'
                color="orange"
                variant="filled"
                onClick={() => multiple ? value.forEach(v => window.open(v, "_blank")) : window.open(value, "_blank")}
                style={{position: 'absolute', bottom: 5, right: 5}}><FaEye /></ActionIcon>
            }
        </div>
    }
    if(fieldType === InputFieldTypes.TEXTAREA){
        return <Textarea
            autosize
            minRows={minRows}
            maxRows={maxRows}
            label={title}
            value={value ? value : ""}
            title={title}
            size={size}
            variant={variant}
            placeholder={placeholder ?? title}
            style={style}
            styles={styles}
            onChange={(e) => onChange && onChange({[name]: e.target.value})}
            {...others}
            // sx={{ ["& .mantine-Textarea-label"]: { fontWeight: "bold" } }}
        />
    }
    return <>
        <TextInput style={{display: 'none'}}/>
        {
            mask === InputFieldMasks.MONEY
            ? <NumberFormat 
                value={value ? parseFloat(value) : ""} 
                displayType={'input'} 
                thousandSeparator=','
                decimalSeparator="."
                customInput={TextInput}
                decimalScale={2}
                fixedDecimalScale
                prefix={'$ '}
                onValueChange={(values) => {
                    const { value } = values;
                    onChange && onChange({ [name]: value });
                }}
                inputMode="decimal"
                label={title}
                placeholder={placeholder ?? title}
                style={style}
                styles={styles}
                variant={variant}
                size={size}
                {...others}
                // sx={{ ["& .mantine-TextInput-label"]: { fontWeight: "bold" } }}
                type={fieldType === InputFieldTypes.NUMBER ? "number" : others.type}
            />
            : mask === InputFieldMasks.CUSTOM
            ? <InputMask
                mask={customMask}
                value={props.value}
                maskPlaceholder={maskPlaceholder}
                maskChar={maskChar}
                onChange={(e) => onChange && onChange({[name]: e.target.value})}
                beforeMaskedValueChange={beforeMaskValueChange}
            >
                {(props) => 
                <TextInput
                    label={title}
                    value={value}
                    title={title}
                    placeholder={placeholder ?? title}
                    icon={icon}
                    style={style}
                    variant={variant}
                    size={size}
                    {...others}
                    {...props}
                />}
            </InputMask>
            : fieldType === InputFieldTypes.PASSWORD
            ? <PasswordInput
                value={value ? value : ""}
                title={title}
                placeholder={placeholder ?? title}
                // label={value ? title : null}
                label={title}
                style={style}
                variant={variant}
                size={size}
                onChange={(e) => onChange && onChange({[name]: e.target.value})}
                {...others}
            />
            : <TextInput
                value={value ? value : ""}
                title={title}
                placeholder={placeholder ?? title}
                // label={value ? title : null}
                label={title}
                style={style}
                styles={styles}
                variant={variant}
                size={size}
                onChange={(e) => onChange && onChange({[name]: e.target.value})}
                {...others}
                type={fieldType === InputFieldTypes.NUMBER ? "number" : others.type}
                // sx={{ ["& .mantine-TextInput-label"]: { fontWeight: "bold" } }}
            />
        }
    </>
} 