import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { GET_COMPANY_INTEGRATION, SET_COMPANY_INTEGRATION } from "../../services/companies";
import InputField, { InputFieldTypes } from "../input_field";
import { Avatar, Button, Group, Text, Title } from "@mantine/core";

export default function Stripe(){
    const [data, setData] = useState<any>(null);
    const [loadingSave, setLoadingSave] = useState<boolean>(false);

    const handleChange = dt => setData(u => ({ ...u, ...dt }))

    const loadData = () => {
        GET_COMPANY_INTEGRATION("stripe")
        .then((res) => {
            setData(res)
        })
        .catch(err => {
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }
    
    const handleSave = () => {
        setLoadingSave(true)
        SET_COMPANY_INTEGRATION("stripe", {...data})
        .then((res) => {
            setLoadingSave(false)
            notifications.show({message: "Connected on stripe to receive payments", color: 'green'})
        })
        .catch(err => {
            setLoadingSave(false)
            notifications.show({title: "Ops.", message: err.message, color: 'red'})
        })
    }

    React.useEffect(() => { loadData() }, [])

    return <>
        <Group>
            <Avatar size={40} src="/assets/stripe.png" />
            <Title order={5}>Stripe</Title>
        </Group>
        <InputField
            mt="md"
            name="key"
            value={data?.key}
            fieldType={InputFieldTypes.PASSWORD}
            onChange={handleChange}
            title="API KEY"
        />
        <InputField
            mt="md"
            name="secret"
            value={data?.secret}
            fieldType={InputFieldTypes.PASSWORD}
            onChange={handleChange}
            title="SECRET KEY"
        />

        {(data?.key?.includes("test") || data?.secret?.includes("test")) &&
            <Text c="red" size="xs">You need to insert live credentials to connect into stripe</Text>}

        <Group justify="flex-end" mt="md">
            <Button
                size="xs"
                onClick={() => {
                    SET_COMPANY_INTEGRATION("stripe", { key: "", secret: "" })
                    .then(() => {
                        setData(d => ({ ...d, key: "", secret: "" }))
                        notifications.show({message: "Disconnected", color: 'green'})
                    })
                    .catch(err => { notifications.show({title: "Ops.", message: err.message, color: 'red'}) })
                }}
                color="red"
                variant="transparent"
                className="draw-button"
            >Disconnect</Button>
            <Button
                disabled={
                    (data?.key?.includes("test") || data?.secret?.includes("test")) ||
                    ((data?.key ?? "").length < 10) || (data?.secret ?? "").length < 10
                }
                size="md"
                onClick={handleSave}
                loading={loadingSave}
                color="green"
                className="draw-button"
            >Connect</Button>
        </Group>
    </>
}
