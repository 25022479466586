import { Box, LoadingOverlay, Modal, Text, UnstyledButton, Title, Paper } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, {
  createContext,
  useCallback,
  useContext, useEffect, useLayoutEffect,
  useMemo,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../services/api";
import { AUTH_LOGIN, COMPANY_BY_HOST, RESEND_VALIDATION } from "../services/auth";
import { CHANGE_USER_STATUS, GET_SELF_PROFILE, GET_USER_DATA } from "../services/users";
import { RouteProps } from "../utility/routes";
import { Helmet } from 'react-helmet'
import ChoosePlan from "../pages/choose_plan";

interface AuthContextProps {
  user: any;
  ip: string;
  planData: any;
  userData: any;
  companyHost: any;
  role: any;
  loadUserData: () => void;
  onChangeRole: (roleId : number, asClient? : boolean) => void;
  roles: any[];
  teams: any[];
  hasPermission: (route : RouteProps) => boolean;
  changeStatus: (status: string) => void;
  logout: () => void;
  login: (
    email: string,
    password: string,
    callback?: () => void,
    errorCallback?: (err: any) => void,
  ) => Promise<void>;
  signIn: (
    user : any,
    callback?: () => void
  ) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const localRole = localStorage.getItem("@retainr/role");
  const lsUser = localStorage.getItem("@retainr/user");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [viewAsClient, setViewAsClient] = useState(false);
  const [loadingCompanyByHost, setLoadingCompanyByHost] = useState(true);
  const [showChildren, setShowChildren] = useState(true);

  const [user, setUser] = useState(lsUser ? JSON.parse(lsUser) : null);
  const [role, setRole] = useState(localRole ? JSON.parse(localRole) : null);
  const [userData, setUserData] = useState(null);
  const [companyHost, setCompanyHost] = useState(null);

  const [ip, setIp] = useState<string>("");
  
  const navigate = useNavigate();

  const loadUserData = async () => {
    return new Promise(async (resolve) => {
      try{
        setLoadingUserData(true)
        const res = await GET_USER_DATA()
        setUserData(res);
        setLoadingUserData(false);
        if(res && !res?.user?.role){
          logout()
        }else{
          setRole(res?.user?.role);
        }
        resolve(res)
      }catch(err){
        setLoadingUserData(false)
        if(err.message === "Unauthorized"){
          localStorage.removeItem("@retainr/role");
          localStorage.removeItem("@retainr/user");
          window.location.href = "/login"
        }else{
          setUserData(null);
          setRole(null);
        }
        resolve(null)
      }
    })
  }
  
  const loadCompanyHost = async () => {
    setLoadingCompanyByHost(true)
    COMPANY_BY_HOST()
    .then(res => {
      setLoadingCompanyByHost(false)
      setCompanyHost(res)
    })
    .catch(err => {
      setLoadingCompanyByHost(false)
    })
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const userIP = data.ip;
        setIp(userIP)
      } catch (error) { setIp(""); }
    })();
  }, [])

  // useEffect(() => {
  //   if(role) loadUserData();
  // }, [role])
  
  useEffect(() => {
    if(user) loadUserData();
  }, [user])

  useEffect(() => {
    if(role) localStorage.setItem("@retainr/role", JSON.stringify(role));
    else setRole(null);
  }, [role])

  const signIn = async (user, callback) => {
    localStorage.setItem("@retainr/user", JSON.stringify(user));
    setUser(user);
    setIsLoading(false);
    callback();
  }

  const login = useCallback(
    async (email: string, password: string, callback?: () => void, errorCallback?: (err) => void) => {
      try {
        const {user} = (await AUTH_LOGIN({ email, password }));
        signIn(user, callback);
      } catch (err) {
        errorCallback && errorCallback(err);
        notifications.show({ color: "red", title: "Erro!", message: err.message });
        console.error({ err });
      }
    },
    []
  );

  const hasPermission = (route : RouteProps) => {
    return true
    return !route.private
      || (
        userData && 
        (userData.resources || []).some(r => r.key === route.key)
      )
  }

  const logout = () => {
    localStorage.removeItem('@retainr/user');
    localStorage.removeItem('@retainr/role');
    setUser(null)
    setRole(null)
    window.location.reload();
  }

  const onChangeRole = (roleId, asClient = false) => {
    // if(refresh){
      setShowChildren(false);
      setTimeout(() => setShowChildren(true), 10);
    // }
    const role = (userData?.user?.roles || []).find(r => `${r._id}` === `${roleId}`);
    setRole(role);
    setViewAsClient(asClient);
    loadUserData();
  }

  const changeStatus = (status) => {
    CHANGE_USER_STATUS(status)
    .then(() => {})
    .catch(() => {})
  }

  useEffect(() => {
    if(user){
      return () => { changeStatus("inactive") }
    }
  }, [user])
  
  useEffect(() => {
    loadCompanyHost();
  }, [])
  
  // useEffect(() => {
  //   if(companyHost?.addJs){
  //     const script = document.createElement("script");
  //     script.innerHTML = companyHost?.addJs.startsWith("<script>")
  //       ? companyHost?.addJs.split("<script>")[1]?.split("</script>")[0]
  //       : companyHost?.addJs;
  //     document.head.appendChild(script);
  //     return () => { document.head.removeChild(script); };
  //   }
  // }, [companyHost?.addJs])

  const value = useMemo<AuthContextProps>(() => {
    return {
      ip,
      user,
      companyHost,
      userData,
      onChangeRole,
      planData: userData?.plan,
      role: role 
        ? { ...role, profile: role.profile
          ? {...role.profile, title: viewAsClient ? "Client" : role.profile?.title }
          : role.profile
        }
        : role,
      teams: userData?.user?.teams || [],
      roles: (userData?.user?.roles || []),
      changeStatus,
      loadUserData: () => {
        loadUserData();
        loadCompanyHost();
      },
      signIn, login, hasPermission, logout
    };
  }, [user, role, userData, viewAsClient, login, signIn, hasPermission]);

  const someLoading = isLoading || loadingUserData || loadingCompanyByHost;

  return (
    <AuthContext.Provider value={value}>
      {!someLoading && showChildren && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
